import React, {memo, useEffect, useLayoutEffect, useRef, useState} from 'react';
import s from './Requirements.module.scss'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectUserData} from "../../store/userReducer";
import Box from '@mui/material/Box';

import {
    DataGridPro, GridRow,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import {gridDataHelper, utilityDataHelper} from "../../helpers/localStorageHelper";
import {InputLabel, Pagination} from "@mui/material";
import {useNavigate} from "react-router";
import {
    clearPropertiesForEmail,
    clearSurfacesForEmail,
    onClearCheckedProperties,
    onClearCheckedSurfaces,
    onFilterPropertiesGrid,
    onResetPropertiesGridColumns,
    onSetAllSurfaceClosed,
    onSetIsCheckReqMatchFirstTime,
    onSetLocalSortModel,
    onSetPropertiesGridLoaderMessage,
    onSetPropertiesOfferFormError,
    onSetProperties,
    onSetReqGridHeight,
    onSetSortingString,
    setShownColumns,
    onClearPropertiesMessageBody,
    selectActivitiesModalOpened,
    onSetIsActivityModalOpened,
    onSetPropertiesMessageBody,
    ShownAndHiddenColumnsType,
    GetPropertiesGridContacts,
    onSetIs404Error,
    onResetDefaultReqContacts,
    onChangeReqPropsWindowModesMode,
} from "../../store/propertiesReducer";
import {Button, message, Select} from "antd";
import searchIcon from "../../img/icons/searchTable.svg";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {customSortFunction} from "../../helpers/sortingDataHelper";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import {
    CreateNewActivityThunk,
    GetActivitiesCompaniesByContactIdThunk,
    GetRequirementsContactsThunk,
    onChangeModalActivityFormField,
    onClearActivitiesContacts,
    onClearActivitiesPropertiesAndSurfaces, onClearSearchedCompanies,
    onClearSearchedCompaniesViaContact,
    onSetActivitiesContactId,
    onSetActivitiesOrganizationId,
    onSetCurrentContactRef,
    onSetCurrentModalTab,
    onSetIsDescriptionFieldError,
    onSetIsSavingButtonDisabled,
    onSetRequirementsSearchedContact,
    onSetRequirementsSearchedOrganization,
    SearchContactsByCompanyNameThunk,
    selectActivitiesKindNumberEntity,
    selectActivitiesModalFormFields,
    selectActivitiesTypeNumberEntity,
    selectActivityFormContacts,
    selectActivityKindTypeIsPropAndReqRequired,
    selectContactId,
    selectContactsWasNotFound,
    selectCurrentContactRef,
    selectOrganizationId, selectRequirementsSearchedContact,
    selectRequirementsSearchedOrganization,
    selectSearchedCompaniesViaContact,
    selectSearchedContactsViaCompanies,
    setContactsWasFound,
} from "../../store/activitesGrid";
import ActivitiesGridForm from "../ActivitiesGridForm/ActivitiesGridForm";
import CommonActivityModal from "../common/CommonActivityModal/CommonActivityModal";
import {CreateAndUpdateActivityRequest} from "../../app/api";
import Tooltip from "../Tooltip/Tooltip";
import PropertiesHeaderButtons from "../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";

import PropertiesViewOptions from "../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
import {
    GetColdOfferRequirementLinkedPropertiesThunk,
    GetRequirementCriteriasForReqEditThunk,
    GetRequirementsByCompanyIdThunk,
    GetRequirementsByContactIdGridDataThunk,
    GetRequirementsColdOffersGridDataThunk,
    GetRequirementsGridDataThunk,
    onChangeColdOffersReqGridColumnsPosition,
    onChangeColdOffersReqGridColumnsWidth,
    onChangeCreateReqCurrentStep,
    onChangeIsStepsClickAvailable,
    onChangeReqGridColumnsPosition,
    onChangeReqGridColumnsWidth,
    onChangeRequirementsCurrentGridType,
    onClearReqCreationFields,
    onReqResetFiltersButtonsSelected,
    onResetContactsReqs,
    onSetColdOfferRequirementIsOpened,
    onSetFiltersForRequirementsColdOfferGrid,
    onSetFiltersForRequirementsGrid,
    onSetIsOrganizationStepSkipped,
    onSetIsReqDataLoading,
    onSetIsReqEditMode,
    onSetOrganizationName,
    onSetReqColdOfferLocalSortModel,
    onSetRequirements,
    onSetRequirementsColdOfferLocalSortModel,
    onSetRequirementsLocalSortModel, selectIsReqDataLoading,
    selectRequirementColdOfferGridColumnsForFilters,
    selectRequirementGridColumnsForFilters,
    selectRequirements,
    selectRequirementsColdOffer,
    selectRequirementsColdOfferColumns,
    selectRequirementsColdOfferGridColumns,
    selectRequirementsColdOfferHiddenColumns,
    selectRequirementsColdOfferShownColumns,
    selectRequirementsColdOffersSortingModel,
    selectRequirementsColdOffersSortingString,
    selectRequirementsColumns,
    selectRequirementsDefaultGridColumns,
    selectRequirementsGridCurrentType,
    selectRequirementsGridFiltersForArray,
    selectRequirementsHiddenColumns,
    selectRequirementsShownColumns,
    selectRequirementsSortingModel,
    selectRequirementsSortingString,
    setColdOfferReqGridColumnsForFilters,
    setReqGridColumnsForFilters,
    setRequirementsColdOfferHiddenColumns,
    setRequirementsColdOfferShownColumns,
    setRequirementsHiddenColumns,
    setRequirementsShownColumns
} from "../../store/requirementsReducer";
import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import PropertyFilterModal from "../PropertiesPage/PropertyFilterModal/PropertyFilterModal";
import {
    selectCurrentScreenWidth,
    selectIsMobileFiltersOpened,
} from "../../store/utilityReducer";
import {debounce} from "../../customFunctions/debounceScreenHeightChange";
import MobileFiltersButton from "../common/MobileFiltersButton/MobileFiltersButton";
import MobileFiltersMenu from "../common/MobileFiltersMenu/MobileFiltersMenu";
import {GridColumns, MuiGridDataColumns} from "../../types/commonTypes";
import {GridSortItem} from "@mui/x-data-grid";
import {RequirementType} from "../../types/parametersTypes";
import {saveGridConfigs} from "../../commonGridFunctions/saveGridConfigs";
import {resetGridConfigs} from "../../commonGridFunctions/resetGridConfigs";
import {getShownAndHiddenColumns} from "../../commonGridFunctions/getShownAndHiddenColumns";
import CreationModal from "../common/CreationModal/CreationModal";
import {LoadingOutlined, QuestionCircleOutlined, CopyOutlined} from "@ant-design/icons";
import switchImg from "../../img/icons/switcher.png";

import {
    GetAllCompaniesThunk,
    onClearCompanies, onSetCompanyWasNotFound,
    onSetIsCompaniesLoading,
    selectAllCompanies, selectCompanyWasNotFound, selectIsCompaniesLoading
} from "../../store/companies";
import PrimaryButton from "../common/Buttons/PrimaryButton/PrimaryButton";
import {sessionStorageGridFilters, sessionStorageUtilityValues} from "../../helpers/sessionStorageHelper";
import {gridFiltering} from "../../customFunctions/gridFiltering";
import {PropertiesColumnType} from "../../types/propertiesTypes";
import GridTabs from "../common/GridTab/GridTabs";
import GridTabButton from "../common/GridTab/GridTabButton/GridTabButton";
import surfaceArrow from "../../img/icons/chevron-down.svg";



const Requirements = () => {
    return (
        <PageWithSidebar>
            <RequirementsComponent/>
        </PageWithSidebar>
    )
}

const RequirementsComponent = () => {
    const dispatch = useAppDispatch()
    const requirementsRows = useAppSelector(selectRequirements)
    const requirementsColdOfferRows = useAppSelector(selectRequirementsColdOffer)
    const userData = useAppSelector(selectUserData)
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 340);
    const [loading, setIsLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const rowsPerPageOptions = [100, 150, 200];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const isUserAddedPropAndReq = useAppSelector(selectActivityKindTypeIsPropAndReqRequired)
    const [localSortModel, setLocalSortModel] = useState({});
    const [localColdOffersSortModel, setLocalColdOffersSortModel] = useState({});
    const modalFormFields = useAppSelector(selectActivitiesModalFormFields)
    const typeNumberEntity = useAppSelector(selectActivitiesTypeNumberEntity)
    const kindNumberEntity = useAppSelector(selectActivitiesKindNumberEntity)
    const organizationId = useAppSelector(selectOrganizationId)
    const contactId = useAppSelector(selectContactId)
    const isModalOpened = useAppSelector(selectActivitiesModalOpened)
    // eslint-disable-next-line
    const threeMonthsAgo = new Date(); // Get today's date
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // Set three months ago
    // eslint-disable-next-line
    const today = new Date(); // Get today's date
    const requirementsColumns = useAppSelector(selectRequirementsColumns)
    const requirementsColdOfferColumns = useAppSelector(selectRequirementsColdOfferColumns)
    const [filtersShown, setFiltersShown] = useState(false)
    const requirementsSortModel = useAppSelector(selectRequirementsSortingModel)
    const requirementsSortingRule = useAppSelector(selectRequirementsSortingString)
    const requirementsColdOfferSortModel = useAppSelector(selectRequirementsColdOffersSortingModel)
    const requirementsColdOfferSortingRule = useAppSelector(selectRequirementsColdOffersSortingString)
    const [checkedViewOptions, setCheckedViewOptions] = useState<string[]>([]);
    const requirementsShownColumns = useAppSelector(selectRequirementsShownColumns)
    const requirementsHiddenColumns = useAppSelector(selectRequirementsHiddenColumns)
    const requirementsColdOfferShownColumns = useAppSelector(selectRequirementsColdOfferShownColumns)
    const requirementsColdOfferHiddenColumns = useAppSelector(selectRequirementsColdOfferHiddenColumns)
    const requirementColdOfferDefaultGridColumns = useAppSelector(selectRequirementsColdOfferGridColumns)
    const defaultRequirementGridColumns = useAppSelector(selectRequirementsDefaultGridColumns)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const isMobileMenuOpened = useAppSelector(selectIsMobileFiltersOpened)
    const [switched, setSwitched] = useState(true)
    let typingTimeout: any;
    const companies = useAppSelector(selectAllCompanies)
    const formContacts = useAppSelector(selectActivityFormContacts)
    const contactsWithoutOrganizationSearched = useAppSelector(selectSearchedCompaniesViaContact)
    const isCompaniesLoading = useAppSelector(selectIsCompaniesLoading)
    const searchedContactsViaCompanies = useAppSelector(selectSearchedContactsViaCompanies)
    const currentContactRef = useAppSelector(selectCurrentContactRef)
    const navigate = useNavigate()
    const contactWasNotFound = useAppSelector(selectContactsWasNotFound)
    const [isSearchContactModalOpened, setSearchContactModalOpened] = useState(false)
    const [searchedContact, setSearchedContact] = useState('')
    const [searchedCompany, setSearchedCompany] = useState('')
    const requirementsSearchedOrganization = useAppSelector(selectRequirementsSearchedOrganization)
    const requirementsSearchedContact = useAppSelector(selectRequirementsSearchedContact)
    const gridFilters = useAppSelector(selectRequirementsGridFiltersForArray)
    const companyWasNotFound = useAppSelector(selectCompanyWasNotFound)
    const requirementsColumnsForFilters = useAppSelector(selectRequirementGridColumnsForFilters)
    const requirementsColdOfferColumnsForFilters = useAppSelector(selectRequirementColdOfferGridColumnsForFilters)
    const currentGrid = useAppSelector(selectRequirementsGridCurrentType)
    const requirementsList = currentGrid === 'Requirements' ? requirementsRows : requirementsColdOfferRows
    const requirementsGridColumns = currentGrid === 'Requirements' ? requirementsColumns : requirementsColdOfferColumns
    const shownColumns = currentGrid === 'Requirements' ? requirementsShownColumns : requirementsColdOfferShownColumns
    const hiddenColumns = currentGrid === 'Requirements' ? requirementsHiddenColumns : requirementsColdOfferHiddenColumns
    const defaultGridColumns = currentGrid === 'Requirements' ? defaultRequirementGridColumns : requirementColdOfferDefaultGridColumns
    const columnsForFilters = currentGrid === 'Requirements' ? requirementsColumnsForFilters : requirementsColdOfferColumnsForFilters
    const sortingRule =  currentGrid === 'Requirements' ? requirementsSortingRule : requirementsColdOfferSortingRule
    const sortModel = currentGrid === 'Requirements' ? requirementsSortModel : requirementsColdOfferSortModel
    const [coldOfferRowId, setColdOfferRowId] = useState<null | number>(null)
    const isReqDataLoading = useAppSelector(selectIsReqDataLoading)

    useEffect(() => {
        dispatch(onSetProperties({properties: [], gridType: 'Properties'}))
        dispatch(onSetProperties({properties: [], gridType: 'All Properties/REQ'}))
        dispatch(setShownColumns({columns: [], gridType: 'Properties'}))
        dispatch(onResetPropertiesGridColumns([]))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        gridDataHelper.removeConditionGridFiltersString()
        gridDataHelper.removePropertiesGridConfig()
        gridDataHelper.removeRowId()
        gridDataHelper.removeRowTitle()
        gridDataHelper.removePropertiesGridFilters()
        dispatch(onClearPropertiesMessageBody())
        dispatch(clearPropertiesForEmail([]))
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetAllSurfaceClosed())
        dispatch(onClearCheckedProperties())
        dispatch(onClearCheckedSurfaces())
        dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: 'Properties'}))
        dispatch(onSetSortingString({sorting_string: null, gridType: 'Properties'}));
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}));
        dispatch(onSetPropertiesGridLoaderMessage(''))
        gridDataHelper.removeIsCheckReqMatchFirstTime()
        dispatch(onSetIsCheckReqMatchFirstTime(false))
        /////reset activity modal fields
        dispatch(onSetIsActivityModalOpened(false))
        dispatch(onSetIsDescriptionFieldError(false))
        dispatch(clearPropertiesForEmail([]))
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetAllSurfaceClosed())
        dispatch(onClearCheckedProperties())
        dispatch(onClearCheckedSurfaces())
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        dispatch(onSetPropertiesMessageBody(''))
        dispatch(onClearActivitiesPropertiesAndSurfaces())
        //
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Description'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Remark'}))
        dispatch(onResetContactsReqs())
        dispatch(onSetCurrentContactRef(0))
        dispatch(setContactsWasFound(false))
        dispatch(onSetCompanyWasNotFound(false))
        dispatch(onChangeIsStepsClickAvailable(false))
        dispatch(onClearReqCreationFields())
        dispatch(onSetRequirementsSearchedContact(null))
        dispatch(onChangeCreateReqCurrentStep('Create contact'))
        dispatch(onSetActivitiesOrganizationId(null))
        gridDataHelper.removeCurrentCreateReqDomain()
        dispatch(onSetIsOrganizationStepSkipped(false))
        dispatch(onClearCompanies())
        dispatch(onClearSearchedCompaniesViaContact())
        dispatch(onClearActivitiesContacts())
        dispatch(onSetRequirementsSearchedContact(null))
        dispatch(onSetRequirementsSearchedOrganization(null))
        dispatch(onSetIs404Error(false))
        dispatch(onSetFiltersForRequirementsGrid({gridFilters: []}))
        gridDataHelper.removeCurrentSearchedContactId()
        gridDataHelper.removeCurrentSearchedCompanyId()
        gridDataHelper.removeCurrentSearchedContactName()
        gridDataHelper.removeCurrentCreateReqDomain()
        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Organizations'}))
        dispatch(onClearSearchedCompanies())
        dispatch(onSetCurrentContactRef(0))
        dispatch(onSetIsReqEditMode(false))
        sessionStorageUtilityValues.removeCurrentReqIdForReqEdit()
        dispatch(onResetDefaultReqContacts())
        utilityDataHelper.removeSearchedRequirementsContactRef()
        dispatch(onChangeReqPropsWindowModesMode({modeName: 'Email', mode: 'Closed'}))
        dispatch(onChangeReqPropsWindowModesMode({modeName: 'Activity', mode: 'Closed'}))
        gridDataHelper.removeCurrentRequirementsGridMode()
    }, [dispatch])

    useEffect(() => {
        if (currentGrid === 'Requirements') {
            dispatch(GetRequirementsGridDataThunk({
                p_agent_ref: Number(userData.user_ref),
                p_sort_order: sortingRule,
                p_start_period: threeMonthsAgo,
                p_end_period: today
            }))
        } else {
            dispatch(GetRequirementsColdOffersGridDataThunk({p_agent_ref: Number(userData.user_ref), p_sort_order: sortingRule}))
        }

        // eslint-disable-next-line
    }, [dispatch, userData, currentGrid])

    const handleSortModelChange = (newModel: GridSortItem[] | []) => {
        const newLocalModel: any = currentGrid === 'Requirements' ? {...localSortModel} : {...localColdOffersSortModel}
        newModel.forEach((model: GridSortItem) => {
            newLocalModel[model.field] = model
        })
        if(currentGrid === 'Requirements'){
            setLocalSortModel(newLocalModel)
        }
        else{
            setLocalColdOffersSortModel(newLocalModel)
        }
        const mergedArray = [...sortModel, ...newModel]
        // Find the index of the matching field object in the merged array
        const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);
        // Check if the sort value is different and update it
        if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
            // eslint-disable-next-line
            const updatedObject = {
                ...mergedArray[index],
                sort: newModel[0]?.sort
            }
            mergedArray[index] = updatedObject;
        }
        if(currentGrid === 'Requirements'){
            dispatch(onSetRequirementsLocalSortModel({sort_model: mergedArray}))
        }
        else{
            dispatch(onSetReqColdOfferLocalSortModel({sort_model: mergedArray}))
        }

    };

    const apiRef = useGridApiRef();

    const [page, setPage] = React.useState(1);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    const rows = customSortFunction(requirementsList, sortModel)
        .map((item: RequirementType) => {
            const {INFORMER, ...rest} = item;
            return rest;
        })
        .filter((row: any) => {
            const entries = Object.entries(row);
            const useStartsWith = searchTerm.endsWith('%');
            const processedSearchTerm = (useStartsWith ? searchTerm.slice(0, -1) : searchTerm).toLowerCase();

            return entries.some(([key, value]) => {
                if (key !== 'MODIFIED_BY') {
                    const stringValue = String(value).toLowerCase();
                    return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(processedSearchTerm);
                }
                return false;
            });
        });

    const paginatedRows = rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);


    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event)
    }

    const getRowClassName = (params: any) => {
        return (params.id % 2 === 0) ? 'even-row' : 'odd-row';
    };

    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage - 340);
            dispatch(onSetReqGridHeight(heightForPage))
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [dispatch]);


    const onCloseModal = () => {
        dispatch(onSetIsActivityModalOpened(false))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Description'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Remark'}))
    }

    const onCreateNewActivity = () => {
        setIsLoading(true)
        if (isUserAddedPropAndReq === 0) {
            const link_type_list: any = []
            const link_entity_list: number | null[] = []
            let link_ref_list = Array.from({length: link_entity_list.length + 2}, () => null)
            link_type_list.push(2, 3)
            // @ts-ignore
            link_entity_list.push(organizationId, contactId)
            if (modalFormFields.description !== '') {
                dispatch(onSetIsSavingButtonDisabled(true))
                const req: CreateAndUpdateActivityRequest = {
                    date_begin: moment(modalFormFields.date_from),
                    date_end: moment(modalFormFields.date_till),
                    description: modalFormFields.description !== '' ? modalFormFields.description : '',
                    link_entity_list: link_entity_list,
                    link_ref_list: link_ref_list,
                    link_type_list: link_type_list,
                    priority: modalFormFields.urgency === 'low' ? 0 : modalFormFields.urgency === 'avg' ? 1 : 2,
                    remark: modalFormFields.remark !== '' ? modalFormFields.remark : '',
                    type: typeNumberEntity,
                    type_kind: kindNumberEntity
                }
                dispatch(CreateNewActivityThunk({activityData: req, isActivityGrid: false}))
                    .then(() => {
                        setIsLoading(false)
                        dispatch(onSetIsActivityModalOpened(false))
                    })

            } else {
                dispatch(onSetIsDescriptionFieldError(true))
                dispatch(onSetCurrentModalTab('General'))
                setIsLoading(false)
            }
        } else {
            message.warn('You need to select REQ and at least one property in order to save activity')
            dispatch(onSetIsSavingButtonDisabled(false))
            setIsLoading(false)
        }

    }

    let columns = requirementsGridColumns.map((g: GridColumns) => {
        if (g.COL_NAME === 'Actions') {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: 0,
                dataType: g.COL_TYPE,
                col_pos: 7,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        } else {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }
        }
    })
    const onSaveGridConfigs = () => {
        if(currentGrid === 'Requirements'){
            saveGridConfigs(shownColumns, hiddenColumns, requirementsColumns, localSortModel, userData, threeMonthsAgo, today, 'Requirements', dispatch)
        }
        else{
            saveGridConfigs(shownColumns, hiddenColumns, requirementsColdOfferColumns, localColdOffersSortModel, userData, threeMonthsAgo, today, 'Requirements/Cold Offer', dispatch)
        }
    }
    const onResetConfigs = () => {
        if(currentGrid === 'Requirements'){
            resetGridConfigs(dispatch, userData, threeMonthsAgo, today, setSearchTerm, setLocalSortModel, "Requirements")
        }
        else{
            resetGridConfigs(dispatch, userData, threeMonthsAgo, today, setSearchTerm, setLocalColdOffersSortModel, "Requirements/Cold Offer")
        }
    }
    const onSetFiltersShown = () => {
        setFiltersShown(!filtersShown)
    }

    const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevent event bubbling to document click event listener
        onSetFiltersShown();
    };
    useEffect(() => {
        if (currentGrid === 'Requirements') {
            dispatch(setRequirementsShownColumns({
                columns: getShownAndHiddenColumns(columns as MuiGridDataColumns[], 'Shown')
            }))
            dispatch(setRequirementsHiddenColumns({
                columns: getShownAndHiddenColumns(columns as MuiGridDataColumns[], 'Hidden')
            }))
        } else {
            dispatch(setRequirementsColdOfferShownColumns({
                columns: getShownAndHiddenColumns(columns as MuiGridDataColumns[], 'Shown')
            }))
            dispatch(setRequirementsColdOfferHiddenColumns({
                columns: getShownAndHiddenColumns(columns as MuiGridDataColumns[], 'Hidden')
            }))
        }
        setCheckedViewOptions(columns.filter((c: any) => c?.width === 0).map((c: any) => c.field))
        // eslint-disable-next-line
    }, [dispatch, requirementsGridColumns])

    useEffect(() => {
        const sortModelObject: any = {};
        for (const item of sortModel) {
            sortModelObject[item.field] = {
                field: item.field,
                sort: item.sort
            };
        }
        if(currentGrid === 'Requirements'){
            setLocalSortModel(sortModelObject)
        }
        else{
            setLocalColdOffersSortModel(sortModelObject)
        }
    }, [sortModel, currentGrid])


    useEffect(() => {
        if(currentGrid === 'Requirements'){
            const filterColumnsFromSessionStorage = sessionStorageGridFilters.getRequirementsGridFilters()
            if (filterColumnsFromSessionStorage && filterColumnsFromSessionStorage.length) {
                dispatch(setReqGridColumnsForFilters({
                    columns: requirementsColumns.map((g: GridColumns) => {

                        return {
                            title: g.COL_NAME,
                            headerName: g.COL_TITLE ?? '',
                            value: null,
                            condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: g.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }
                    })
                }))
                dispatch(onSetFiltersForRequirementsGrid({gridFilters: filterColumnsFromSessionStorage}))
            }
            dispatch(setReqGridColumnsForFilters({
                columns: requirementsColumns.map((g: GridColumns) => {
                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE ?? '',
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
        }
        else{
            const filterColumnsFromSessionStorage = sessionStorageGridFilters.getRequirementsColdOfferGridFilters()
            if (filterColumnsFromSessionStorage && filterColumnsFromSessionStorage.length) {
                dispatch(setColdOfferReqGridColumnsForFilters({
                    columns: requirementsColumns.map((g: GridColumns) => {

                        return {
                            title: g.COL_NAME,
                            headerName: g.COL_TITLE ?? '',
                            value: null,
                            condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: g.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }
                    })
                }))
                dispatch(onSetFiltersForRequirementsColdOfferGrid({gridFilters: filterColumnsFromSessionStorage}))
            }
            dispatch(setColdOfferReqGridColumnsForFilters({
                columns: requirementsColumns.map((g: GridColumns) => {
                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE ?? '',
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
        }
    }, [dispatch, requirementsColumns, currentGrid])

    useEffect(() => {
        if(currentGrid === 'Requirements'){
            const filterColumnsFromSessionStorage = sessionStorageGridFilters.getRequirementsGridFilters()
            if (filterColumnsFromSessionStorage) {
                gridFiltering(filterColumnsFromSessionStorage, defaultGridColumns, columnsForFilters, currentGrid, dispatch)
            }
        }
        else{
            const filterColumnsFromSessionStorage = sessionStorageGridFilters.getRequirementsColdOfferGridFilters()
            if (filterColumnsFromSessionStorage) {
                gridFiltering(filterColumnsFromSessionStorage, defaultGridColumns, columnsForFilters, currentGrid, dispatch)
            }
        }
        // eslint-disable-next-line
    }, [dispatch, defaultGridColumns, currentGrid])

    // We have custom column position in our state, so default function, provided MUI data grid doesn't fit our app. So we get the column order from state and then sort them in our state in redux
    const handleStateChange = (params: any) => {
        // Access the grid state
        if(currentGrid === 'Requirements'){
            dispatch(onChangeReqGridColumnsPosition({columns: params.columns.all}))
            dispatch(onChangeReqGridColumnsWidth({columns: params.columns.lookup}))
        }
        else{
            dispatch(onChangeColdOffersReqGridColumnsPosition({columns: params.columns.all}))
            dispatch(onChangeColdOffersReqGridColumnsWidth({columns: params.columns.lookup}))
        }


    };
    const onResetFilters = () => {
        dispatch(onSetRequirements({requirementsRows: defaultGridColumns}))
        const resetShownColumns = shownColumns.map((c: ShownAndHiddenColumnsType) => c && {
            ...c,
            isFilterSelected: false,
            isModalBtnOpened: false
        })
        dispatch(setRequirementsShownColumns({columns: resetShownColumns}))
        dispatch(onReqResetFiltersButtonsSelected())
        dispatch(onSetFiltersForRequirementsGrid({gridFilters: []}))
        sessionStorageGridFilters.removeRequirementsGridFilters()
    }

    const onResetSorting = () => {
        if(currentGrid === 'Requirements'){
            dispatch(onSetRequirementsLocalSortModel({sort_model: []}))
            setLocalSortModel({})
        }
       else{
            dispatch(onSetRequirementsColdOfferLocalSortModel({sort_model: []}))
            setLocalColdOffersSortModel({})
        }
    }

    const companiesList = companies.map((company: any, index) => ({
        value: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        label: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        key: company.REF,
        ref: company.REF
    }))

    const contacts = formContacts.flat(1).map((contact: any) => ({
        value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''} | Ref: ${contact.PE_REF} ${contact?.ADDR_CITY && true ? ` | City: ${contact?.ADDR_CITY}` : ''}  ${contact?.ADDR_STREET_ADDRESS && true ? ` | Street: ${contact?.ADDR_STREET_ADDRESS}` : ''}`,
        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''} | Ref: ${contact.PE_REF} ${contact?.ADDR_CITY && true ? ` | City: ${contact?.ADDR_CITY}` : ''}  ${contact?.ADDR_STREET_ADDRESS && true ? ` | Street: ${contact?.ADDR_STREET_ADDRESS}` : ''}`,
        key: contact.PE_REF,
        ref: contact.PE_REF,
        name: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
        email: `${contact?.PE_EMAIL && true ? contact?.PE_EMAIL : ''}`,
        company: `${contact?.PE_FORM_ADDRESS ? contact.PE_FORM_ADDRESS.trim().split('\r\n')[0].split(',')[0] : ''}`
    }))

    const searchedCompaniesViaContactList = contactsWithoutOrganizationSearched.map((contact: any, index) => ({
        value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''} | Ref: ${contact.PE_REF} ${contact?.ADDR_CITY && true ? ` | City: ${contact?.ADDR_CITY}` : ''}  ${contact?.ADDR_STREET_ADDRESS && true ? ` | Street: ${contact?.ADDR_STREET_ADDRESS}` : ''}`,
        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''} | Ref: ${contact.PE_REF} ${contact?.ADDR_CITY && true ? ` | City: ${contact?.ADDR_CITY}` : ''}  ${contact?.ADDR_STREET_ADDRESS && true ? ` | Street: ${contact?.ADDR_STREET_ADDRESS}` : ''}`,
        key: contact.PE_REF,
        ref: contact.PE_REF,
        name: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
        email: `${contact?.PE_EMAIL && true ? contact?.PE_EMAIL : ''}`,
        company: `${contact?.PE_FORM_ADDRESS ? contact.PE_FORM_ADDRESS.trim().split('\r\n')[0].split(',')[0] : ''}`
    }))

    const searchedContactsViaCompaniesList = searchedContactsViaCompanies.map((company: any, index) => ({
        value: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        label: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        key: company.REF,
        ref: company.REF
    }))

    const onChangeSelectValue = (value: any, selectName: string, option?: any) => {
        if (selectName === 'Organizations' && !switched) {
            dispatch(onSetRequirementsSearchedOrganization(option))
            dispatch(GetRequirementsContactsThunk(option?.ref))
            dispatch(onSetActivitiesOrganizationId(option?.ref))
            dispatch(onClearCompanies())
            gridDataHelper.setCurrentSearchedCompanyId(option?.ref)
            dispatch(onSetOrganizationName(option.label.split('|')[0]))
            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
        }
        //////
        else if (selectName === 'Organizations' && switched) {
            dispatch(onSetRequirementsSearchedOrganization(option))
            dispatch(onSetActivitiesOrganizationId(option.ref))
            gridDataHelper.setCurrentSearchedCompanyId(option?.ref)
            gridDataHelper.setCurrentSearchedCompanyId(option?.ref)
        }
        ///////
        else if (selectName === 'Contacts' && !switched) {
            gridDataHelper.setCurrentSearchedContactName(option.name)
            dispatch(onSetRequirementsSearchedContact(option))
            gridDataHelper.setCurrentSearchedContactId(option.ref)
            dispatch(onSetCurrentContactRef(option.ref))
            dispatch(onSetActivitiesContactId(option.ref))
        }
        //////
        else if (selectName === 'Contacts' && switched) {
            gridDataHelper.setCurrentSearchedContactName(option.name)
            dispatch(onSetRequirementsSearchedContact(option))
            gridDataHelper.setCurrentSearchedContactId(option.ref)
            dispatch(GetActivitiesCompaniesByContactIdThunk(option.ref))
            dispatch(onSetCurrentContactRef(option.ref))
            dispatch(onSetActivitiesContactId(option.ref))
            dispatch(onClearSearchedCompaniesViaContact())
            setSearchedContact(option)
        }
    }

    const onSearch = (value: string) => {
        if (!switched) {
            if (value !== '') {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(() => {
                    dispatch(onSetIsCompaniesLoading(true))
                    dispatch(GetAllCompaniesThunk(value))
                    dispatch(onClearCompanies())
                    dispatch(onClearActivitiesContacts())
                    setSearchedCompany(value)
                    dispatch(onChangeModalActivityFormField({value: null, inputName: 'Contacts'}))
                    dispatch(onSetRequirementsSearchedContact(null))
                }, 2000);
            }
        } else {
            if (value !== '') {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(() => {
                    const contactNameParam = value.split('%')[0]
                    const addressParam = /^\d+$/.test(value.split('%')[1] || '') ? '' : value.split('%')[1]
                    const peRefParam = /^\d+$/.test(value.split('%')[1] || '') ? value.split('%')[1] : null;
                    if (addressParam && addressParam.trim() !== '') {
                        dispatch(SearchContactsByCompanyNameThunk({
                            search_value: `${contactNameParam}%`,
                            address: addressParam.trim()
                        }))
                    } else if (peRefParam !== null) {
                        dispatch(SearchContactsByCompanyNameThunk({
                            search_value: `${contactNameParam}%`,
                            pe_ref: `${peRefParam}%`
                        }))
                    } else if (contactNameParam && contactNameParam.trim() !== '' && !/^\d+$/.test(value.trim())) {
                        dispatch(SearchContactsByCompanyNameThunk({search_value: `${contactNameParam}%`}))
                    } else if (/^\d+$/.test(value.trim())) {
                        dispatch(SearchContactsByCompanyNameThunk({search_value: null, pe_ref: `${value}%`}))
                    } else {
                        dispatch(SearchContactsByCompanyNameThunk({search_value: value}))
                    }
                    dispatch(onSetIsCompaniesLoading(true))
                    dispatch(onClearCompanies())
                    setSearchedContact(value)
                    dispatch(onChangeModalActivityFormField({value: null, inputName: 'Organizations'}))
                    dispatch(onSetRequirementsSearchedOrganization(null))
                }, 2000);

            }

        }

    };
    const onSearchModeSwitch = () => {
        setSwitched(!switched)
        dispatch(onClearCompanies())
        dispatch(onClearSearchedCompaniesViaContact())
        dispatch(onSetActivitiesOrganizationId(null))
        dispatch(onSetRequirementsSearchedContact(null))
        dispatch(onSetRequirementsSearchedOrganization(null))
        setSearchedContact('')
        setSearchedCompany('')
        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Organizations'}))
        dispatch(setContactsWasFound(false))
        dispatch(onSetCompanyWasNotFound(false))
        dispatch(onClearSearchedCompanies())
        dispatch(onSetCurrentContactRef(0))
    }

    const handleBlur = () => {
        setSearchedContact(searchedContact)
        setSearchedCompany(searchedCompany)
    };

    const onSearchReqByContact = () => {
        if (currentContactRef !== 0) {
            utilityDataHelper.setSearchedRequirementsContactRef(currentContactRef)
            dispatch(GetRequirementsByContactIdGridDataThunk({
                p_agent_ref: Number(userData.user_ref),
                p_contact_ref: currentContactRef,
                p_sort_order: null
            }))
                .then(() => {
                    if (currentGrid === 'Requirements') {
                        gridDataHelper.setCurrentRequirementsGridMode('Requirements')
                        navigate('/reqs-searched-by-contacts')
                    } else {
                        gridDataHelper.setCurrentRequirementsGridMode('Requirements/Cold Offer')
                        navigate(`/requirements/edit-req/${coldOfferRowId}`)
                    }
                })
        } else {
            dispatch(onSetIsOrganizationStepSkipped(true))
            dispatch(GetRequirementsByCompanyIdThunk(organizationId!!))
                .then(() => {
                    if (currentGrid === 'Requirements') {
                        gridDataHelper.setCurrentRequirementsGridMode('Requirements')
                        navigate('/reqs-searched-by-contacts')
                    } else {
                        gridDataHelper.setCurrentRequirementsGridMode('Requirements/Cold Offer')
                        navigate(`/requirements/edit-req/${coldOfferRowId}`)
                    }
                })
        }
    }

    useEffect(() => {
        dispatch(GetPropertiesGridContacts())
    }, [dispatch])

    const onClearSearchedContacts = (isCreateContact: boolean) => {
        if (isCreateContact) {
            setSearchedContact('')
            setSearchedCompany('')
            dispatch(setContactsWasFound(false))
            dispatch(onSetCompanyWasNotFound(false))
        } else {
            setSearchContactModalOpened(false)
            dispatch(onSetCompanyWasNotFound(false))
            dispatch(setContactsWasFound(false))
            setSearchedContact('')
            setSearchedCompany('')
            dispatch(onSetRequirementsSearchedContact(null))
            dispatch(onSetRequirementsSearchedOrganization(null))
            dispatch(onChangeModalActivityFormField({value: null, inputName: 'Organizations'}))
            dispatch(onClearSearchedCompanies())
            dispatch(onSetCurrentContactRef(0))
        }
    }

    const onCreate = () => {
        if (currentGrid === 'Requirements') {
            gridDataHelper.setCurrentRequirementsGridMode('Requirements')
            navigate('/requirements/create-req-contact')
        } else {
            gridDataHelper.setCurrentRequirementsGridMode('Requirements/Cold Offer')
            navigate(`/requirements/edit-req-cold-offer/${coldOfferRowId}`)
        }
        dispatch(onSetActivitiesOrganizationId(null))
        gridDataHelper.removeCurrentSearchedCompanyId()
    }

    const onReqEdit = (row: PropertiesColumnType) => {
        sessionStorageUtilityValues.setCurrentReqIdForReqEdit(row.REQ_ID)
        gridDataHelper.setCurrentCreateReqDomain(row.DOMAIN)
        if (row.EMAIL_LIST) {
            gridDataHelper.setCurrentSearchedContactName(row.EMAIL_LIST.split(':')[0])
        }
        dispatch(GetRequirementCriteriasForReqEditThunk(row.REQ_ID))

        dispatch(onSetIsReqEditMode(true))
        if (currentGrid === 'Requirements') {
            gridDataHelper.setCurrentRequirementsGridMode('Requirements')
            navigate(`/requirements/edit-req/${row.REQ_ID}`)
        } else {
            gridDataHelper.setCurrentRequirementsGridMode('Requirements/Cold Offer')
            // navigate(`/requirements/edit-req-cold-offer/${row.REQ_ID}`)
            setColdOfferRowId(row.REQ_ID)
            if (row.PERSON_REF !== null) {
                gridDataHelper.setCurrentSearchedContactId(row.PERSON_REF)
                gridDataHelper.setCurrentSearchedCompanyId(row.COMPANY_REF)
                navigate(`/requirements/edit-req/${row.REQ_ID}`)
            }
            setSearchContactModalOpened(true)
        }
    }

    const handleCopy = (fieldType: 'Contact' | 'Switched contact' | 'Organization' | 'Switched organization') => {
        if (fieldType === 'Switched contact') {
            navigator.clipboard.writeText(requirementsSearchedContact!.value)
            message.success('Contact was copied')
        } else if (fieldType === 'Switched organization') {
            navigator.clipboard.writeText(modalFormFields!.organisations!!)
            message.success('Organization was copied')
        } else if (fieldType === 'Organization') {
            navigator.clipboard.writeText(requirementsSearchedOrganization!.value)
            message.success('Organization was copied')
        } else if (fieldType === 'Contact') {
            navigator.clipboard.writeText(requirementsSearchedContact!.value)
            message.success('Contact was copied')
        }
    };


    const onChangeCurrentGridType = (currentGrid: "Requirements" | "Requirements/Cold Offer") => {
        dispatch(onChangeRequirementsCurrentGridType(currentGrid))
        dispatch(onSetIsReqDataLoading(true))
    }

    return (
        <>
            <div className={s.inner} style={{
                marginTop: !isMobileMenuOpened ? '20px' : '10px',
                display: 'flex',
                width: '100%'
            }}>

                <div className={s.tableInner}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <h1 className={s.tableTitle}>Requirements</h1>
                        <div
                            style={{
                                display: 'flex'
                            }}
                            className={s.headerButtons}
                        >
                            {
                                currentScreenWidth! > 768
                                && currentGrid !== 'Requirements/Cold Offer'
                                &&
                                <div style={{
                                    marginRight: '10px'
                                }}
                                     onClick={() => setSearchContactModalOpened(true)}
                                >
                                    <PrimaryButton
                                        text={'Search contacts'}
                                        width={'150px'}
                                        isAddButton={false}
                                        height={'32px'}
                                    />
                                </div>
                            }
                            {
                                currentScreenWidth! > 768
                                &&
                                <PropertiesHeaderButtons
                                    onSetFiltersShown={handleFiltersShownClick}
                                    onResetConfigs={onResetConfigs}
                                    onSaveGridConfigs={onSaveGridConfigs}
                                    // isDisabledSaveBtn={!shownColumns.length}
                                    // disabledBtns={!shownColumns.length}
                                    gridType={currentGrid}
                                    resetFilters={() => {
                                    }}
                                />
                            }
                            {
                                currentScreenWidth! <= 768
                                &&
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}>
                                    <MobileFiltersButton customMargin={'0 12px 10px 10px'}/>
                                    <div style={{
                                        marginRight: '0px'
                                    }}
                                         onClick={() => setSearchContactModalOpened(true)}
                                    >
                                        <PrimaryButton
                                            text={'Search contacts'}
                                            width={'150px'}
                                            isAddButton={false}
                                            height={'40px'}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{
                        marginLeft: '20px',
                        marginTop: '-20px'
                    }}>
                        <GridTabs>
                            <GridTabButton
                                gridType="Requirements"
                                isActive={currentGrid === "Requirements"}
                                onClick={() => onChangeCurrentGridType("Requirements")}
                                buttonName={'List of requirements'}
                            />
                            <GridTabButton
                                gridType="Requirements/Cold Offer"
                                isActive={currentGrid === "Requirements/Cold Offer"}
                                onClick={() => onChangeCurrentGridType("Requirements/Cold Offer")}
                                buttonName={'Cold offer'}
                            />
                        </GridTabs>
                    </div>
                    <div className={s.mobileHeader}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                            marginLeft: '17px'
                        }}>
                            <InputLabel htmlFor="searchInput" style={{marginRight: '8px'}}>
                                <img src={searchIcon} alt="search"/>
                            </InputLabel>
                            <input
                                className={s.search_input}
                                type="text"
                                value={searchTerm}
                                placeholder={'Search by REQ, Name, Contacts'}
                                onChange={handleSearchInputChange}
                            />

                            <div>
                                {searchTerm !== ''
                                    &&
                                    <p
                                        onClick={() => setSearchTerm('')}
                                        style={{
                                            marginBottom: '0',
                                            fontSize: '12px',
                                            color: '#9CA3AF',
                                            cursor: "pointer"
                                        }}>x</p>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        currentScreenWidth! > 768
                        &&
                        <div className={s.filterButtons}>
                            <PropertiesFilterButtons
                                shownColumns={shownColumns}
                                onResetFilters={onResetFilters}
                                onResetSorting={onResetSorting}
                                gridType={currentGrid}
                            />
                        </div>
                    }
                    {
                        currentScreenWidth! > 768
                        &&
                        <div style={{
                            position: 'relative',
                            width: '100%',
                        }}>
                            {shownColumns.map((c: any) => {
                                return (
                                    <PropertyFilterModal
                                        gridType={currentGrid}
                                        textValue={c.field}
                                        isMobile={false}
                                    />
                                )
                            })}
                        </div>
                    }
                    {
                        isReqDataLoading
                            ?
                            <div style={{
                                height: gridContentHeight + 115,
                                width: '400px',
                                margin: '0 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <div style={{
                                    height: '300px',
                                    width: '100%',
                                    textAlign: 'center'
                                }}>
                                    <Lottie
                                        // @ts-ignore
                                        config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                    />
                                </div>

                                <p style={{
                                    textAlign: 'center'
                                }}>Loading</p>
                            </div>
                            :
                            <Box sx={{position: 'relative', width: '100%', padding: '0 10px'}}>
                                <>
                                    <PropertiesViewOptions
                                        checked={checkedViewOptions}
                                        filtersShown={filtersShown}
                                        setChecked={(checkedArray: string[]) => setCheckedViewOptions(checkedArray)}
                                        setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
                                        gridType={currentGrid}
                                    />
                                    <DataGridPro
                                        getRowClassName={getRowClassName}
                                        sx={{
                                            borderRight: 'none',
                                            borderLeft: 'none',
                                            borderBottom: 'none',
                                            borderRadius: '0px',
                                            "& .MuiDataGrid-cellContent": {
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#111827'
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle": {
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 600,
                                                color: '#6B7280',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            },
                                            "& .MuiDataGrid-row:hover": {
                                                backgroundColor: 'transparent'
                                            },
                                            "& .MuiDataGrid-row.Mui-selected": {
                                                backgroundColor: 'transparent'
                                            },
                                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                                backgroundColor: 'transparent'
                                            },
                                            "& .MuiDataGrid-cell:focus": {
                                                outline: 'none'
                                            },
                                            "& .MuiDataGrid-toolbarContainer": {
                                                color: '#454545',
                                                position: 'absolute',
                                                top: '-50px',
                                                right: '0px'
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                margin: '0',
                                                fontFamily: 'Inter, sans-serif !important',
                                                fontWeight: 400,
                                                color: '#6B7280',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            },
                                            "& .MuiPagination-root": {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                padding: '7px 0px'
                                            },
                                            "& .MuiButtonBase-root.Mui-selected": {
                                                backgroundColor: '#F9FAFB',
                                                borderRadius: '0px'
                                            },
                                            "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                width: '40px',
                                                height: '40px',
                                                margin: 0,
                                                borderRight: '1px solid #D0D5DD',
                                                borderLeft: '1px solid #D0D5DD',
                                                borderTop: 'none',
                                                borderBottom: 'none',
                                                fontFamily: 'Inter, sans-serif !important',
                                                fontWeight: 500,
                                                color: '#1D2939',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                borderRadius: '0px'
                                            },
                                            "& .MuiPagination-ul": {
                                                border: '1px solid #D0D5DD',
                                                borderRadius: '8px'
                                            },
                                            "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                borderRadius: '0px',
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                overflowY: 'scroll !important',
                                                height: currentScreenWidth!! < 480 ? `${gridContentHeight - 60}px !important` : `${gridContentHeight - 80}px !important`,
                                                minHeight: currentScreenWidth!! < 480 ? '300px' : 'initial'
                                            },
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: '#FCFDFE'
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: 'none'
                                            },
                                            "& .MuiDataGrid-columnHeader--moving": {
                                                backgroundColor: 'transparent'
                                            },
                                            "& .MuiDataGrid-pinnedColumnHeaders--right": {
                                                paddingRight: currentScreenWidth!! > 600 ? '17px !important' : '0px !important'
                                            }
                                        }}
                                        apiRef={apiRef}
                                        getRowId={e => e.REQ_ID}
                                        columns={shownColumns.filter((col: any) => col?.width !== 0).map((column: any, index: number) => ({
                                            ...column,
                                            renderCell: (params: any) =>
                                                <CustomCell field={column.field}
                                                            value={params.value}
                                                            params={params}
                                                            key={index}
                                                            onReqEdit={() => onReqEdit(params.row)}
                                                            currentGrid={currentGrid}

                                                />,
                                            renderHeader: (params: any) => {
                                                return (
                                                    <div style={{
                                                        display: 'flex',
                                                    }}>
                                                        <span
                                                            className={s.columnHeader}>
                                                            {params?.colDef?.headerName === 'ACTIVITY_EXISTED'
                                                                ? 'ACTIVITY'.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                                : params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}
                                                        </span>
                                                    </div>
                                                );
                                            },
                                        }))}
                                        rows={paginatedRows}
                                        {...paginatedRows}
                                        headerHeight={39}
                                        onStateChange={handleStateChange}
                                        hideFooterSelectedRowCount
                                        onSortModelChange={handleSortModelChange}
                                        sortingOrder={['desc', 'asc']}
                                        sortModel={currentGrid === 'Requirements' ? Object.values(localSortModel) : Object.values(localColdOffersSortModel)}
                                        initialState={{pinnedColumns: {left: [], right: ['ACTIONS']}}}
                                        autoPageSize={true}
                                        rowHeight={25}
                                        autoHeight={true}
                                        components={{
                                            Footer: () =>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}>
                                                    {
                                                        currentScreenWidth! > 768
                                                        &&
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}>
                                                            <p
                                                                className={s.footerText}
                                                            >
                                                                Rows per page:
                                                            </p>
                                                            <Select
                                                                value={rowsPerPage}
                                                                onChange={handleRowsPerPageChange}
                                                                className={s.rowsPerPage}
                                                            >
                                                                {rowsPerPageOptions.map((option) => (
                                                                    <Select.Option key={option} value={option}>
                                                                        {option}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    }
                                                    <Pagination
                                                        count={Math.ceil(gridFilters.length ? (rows.length / rowsPerPage) : (defaultGridColumns.length / rowsPerPage))}
                                                        page={page}
                                                        onChange={handlePageChange}
                                                        variant="outlined"
                                                        shape="rounded"
                                                    />
                                                    {
                                                        currentScreenWidth! > 768
                                                        &&
                                                        <p className={s.footerText}>
                                                            Total{gridFilters.length ? '/Filtered' : ''} requirements: {gridFilters.length ? defaultGridColumns.length : rows.length}{gridFilters.length ? `/${rows.length}` : ''}
                                                        </p>
                                                    }
                                                </div>,
                                            Row: CustomRow
                                        }}
                                    />
                                </>

                            </Box>


                    }
                </div>
                {
                    isModalOpened
                    &&
                    <div style={{
                        marginLeft: '30px'
                    }}>
                        <CommonActivityModal
                            modalTitle={`Activity`}
                            gridType={"Activity"}
                            height={gridContentHeight + 45}
                            onCloseModal={onCloseModal}
                            width={'650px'}
                        >
                            <div className={s.tabButtons}>
                                <button
                                    className={`${s.tabButtons__button} ${s.tabButtons__button_active}`}
                                >
                                    General
                                </button>
                            </div>

                            <>


                                <ActivitiesGridForm withButtons={false} editMode={false} maxWidthInputs={true}/>

                                {
                                    modalFormFields.type === ''
                                    || modalFormFields.type === null
                                    || modalFormFields.kind === ''
                                    || modalFormFields.kind === null
                                        ?
                                        <Tooltip
                                            text={
                                                modalFormFields.type === ''
                                                || modalFormFields.type === null
                                                || modalFormFields.kind === ''
                                                || modalFormFields.kind === null
                                                    ? `Please select the domain , activity type and activity kind in order to save changes.`
                                                    : `Please change your activity kind to the one that is correct with the system kinds`}
                                            classname={'addressButtonsTooltip'}>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                style={{
                                                    height: '44px',
                                                    backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                                    color: '#fff',
                                                    width: '100%',
                                                    border: '1px solid transparent',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: '10px'

                                                }}
                                                onClick={onCreateNewActivity}
                                                disabled={
                                                    modalFormFields.type === ''
                                                    || modalFormFields.type === null
                                                    || modalFormFields.kind === ''
                                                    || modalFormFields.kind === null
                                                }
                                                loading={loading}
                                            >
                                                Add
                                            </Button>
                                        </Tooltip>
                                        :
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            style={{
                                                height: '40px',
                                                backgroundColor: '#0707FA',
                                                border: '1px solid #0707FA',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: '10px'

                                            }}
                                            onClick={onCreateNewActivity}
                                            onDoubleClick={() => setIsLoading(true)}
                                            loading={loading}
                                        >
                                            Add
                                        </Button>
                                }


                            </>


                        </CommonActivityModal>
                    </div>

                }
                {
                    isMobileMenuOpened
                    &&
                    <MobileFiltersMenu
                        checkedViewOptions={checkedViewOptions}
                        filtersShown={true}
                        setCheckedViewOptions={setCheckedViewOptions}
                        setFiltersShown={setFiltersShown}
                        gridType={currentGrid}
                        shownColumns={shownColumns}
                        hiddenColumns={hiddenColumns}
                        userData={userData}
                        today={today}
                        threeMonthsAgo={threeMonthsAgo}
                        gridColumns={requirementsColumns}
                        setSearchTerm={setSearchTerm}
                        setLocalSortModel={currentGrid === 'Requirements' ? setLocalSortModel : setLocalColdOffersSortModel}
                        localSortModel={currentGrid === 'Requirements' ? localSortModel : localColdOffersSortModel}
                        handleFiltersShownClick={handleFiltersShownClick}
                        resetFilters={onResetFilters}
                    />
                }

            </div>
            {
                isSearchContactModalOpened
                &&
                <CreationModal
                    title={'Search contact'}
                    image={<svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#F5F5FF"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" stroke-width="8"/>
                        <g clip-path="url(#clip0_3659_38579)">
                            <g clip-path="url(#clip1_3659_38579)">
                                <path
                                    d="M37 37L32.65 32.65M35 27C35 31.4183 31.4183 35 27 35C22.5817 35 19 31.4183 19 27C19 22.5817 22.5817 19 27 19C31.4183 19 35 22.5817 35 27Z"
                                    stroke="#0707FA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                        </g>
                        <defs>
                            <clipPath id="clip0_3659_38579">
                                <rect width="24" height="24" fill="white" transform="translate(16 16)"/>
                            </clipPath>
                            <clipPath id="clip1_3659_38579">
                                <rect width="24" height="24" fill="white" transform="translate(16 16)"/>
                            </clipPath>
                        </defs>
                    </svg>}
                    onCancel={contactWasNotFound ? () => onClearSearchedContacts(true) : () => onClearSearchedContacts(false)}
                    onSave={onSearchReqByContact}
                    onCreate={onCreate}
                    loading={loading}
                    withMargin={true}
                    width={'640px'}
                    isSearchBtn={true}
                    buttonOkText={currentGrid === 'Requirements' ? 'Next REQ' : 'Edit REQ'}
                    disabledBtn={searchedContact === '' && searchedCompany === ''}
                    buttonCancelText={contactWasNotFound ? 'Clear search' : 'Cancel'}
                    createButton={true}
                    disabledSearchBtn={currentContactRef === 0 || organizationId === null}
                    withTooltip={true}
                    currentGrid={currentGrid}
                >
                    {
                        currentGrid === 'Requirements/Cold Offer' && (!contactWasNotFound && !companyWasNotFound)
                        &&
                        <p className={s.notFoundText}>
                            Edit requirement with searched contact/organization or edit it with creation of a new ones
                        </p>
                    }
                    {
                        contactWasNotFound
                        &&
                        <p className={s.notFoundText}>Your search “{searchedContact}” did not match any Contact. Please
                            try again or create a new contact</p>
                    }
                    {
                        companyWasNotFound
                        &&
                        <p className={s.notFoundText}>Your search “{searchedCompany}” did not match any Company. Please
                            try again or create a new company</p>
                    }
                    {
                        !switched
                            ?
                            <>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <div className={'notificationsSelect reqsSearchInput'} onWheel={(e: any) => {
                                        if (document.activeElement?.tagName === 'INPUT') {
                                            e.preventDefault(); // Prevent scroll from triggering dropdown open when input is focused
                                        }
                                    }}>
                                        <label>Organizations</label>
                                        {/*<Select*/}
                                        {/*    mode="multiple"*/}
                                        {/*    allowClear*/}
                                        {/*    style={{ width: '100%', height: '44px' }}*/}
                                        {/*    placeholder="Please select contact"*/}
                                        {/*    onChange={handleChangeContact}*/}
                                        {/*    options={contactsOptions}*/}
                                        {/*/>*/}

                                        <Select
                                            filterOption={(input, option) =>
                                                (option?.label as string)?.toLowerCase().includes(input.toLowerCase().replace(/%/g, ''))
                                            }
                                            showSearch
                                            placeholder="Enter organization"
                                            optionFilterProp="children"
                                            value={searchedCompany}
                                            onSelect={(value: any, option: any) =>  onChangeSelectValue(value, 'Organizations', option)}
                                            onSearch={onSearch}
                                            options={companiesList}
                                            notFoundContent={null}
                                            onChange={(e) => setSearchedCompany(e)}
                                            // value={modalFormFields.organisations}
                                            style={{
                                                minHeight: '44px',
                                                width: '100%'
                                            }}
                                            onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                            onBlur={handleBlur}
                                            loading={isCompaniesLoading}
                                            suffixIcon={
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {
                                                        requirementsSearchedOrganization
                                                        &&
                                                        <CopyOutlined onClick={() => handleCopy('Organization')}
                                                                      style={{marginRight: 8}}/>
                                                    }
                                                    {!isCompaniesLoading ? (
                                                        <Tooltip
                                                            text="Please, use % in searching string, after/before the actual search value, for example lars% in order to find companies"
                                                            classname={'reqsSearchInputTooltip'}
                                                        >
                                                            <QuestionCircleOutlined/>
                                                        </Tooltip>
                                                    ) : (
                                                        <LoadingOutlined/>
                                                    )}
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%'
                                }}>
                                    <button onClick={onSearchModeSwitch} className={s.switchButton} style={{
                                        top: contactWasNotFound || companyWasNotFound || currentGrid === 'Requirements/Cold Offer' ? '262px' : '224px'
                                    }}>
                                        <img src={switchImg} alt="switch"/>
                                    </button>
                                </div>
                                <div className={'notificationsSelect reqsSearchInput'} style={{
                                    marginTop: '20px'
                                }} onWheel={(e: any) => {
                                    if (document.activeElement?.tagName === 'INPUT') {
                                        e.preventDefault(); // Prevent scroll from triggering dropdown open when input is focused
                                    }
                                }}>
                                    <label>Contacts</label>
                                    <Select
                                        placeholder="Select contact"
                                        // onChange={(value, option) => onChangeSelectValue(value, 'Contacts', option)}
                                        options={contacts}
                                        style={{
                                            minHeight: '44px',
                                            width: '100%',
                                            overflowX: 'auto', // Enable horizontal scrolling
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                        }}
                                        value={requirementsSearchedContact}
                                        // defaultValue={contactsOptions}
                                        // value={contactsOptions}
                                        // value={null}
                                        onSelect={(value: any, option: any) => onChangeSelectValue(value, 'Contacts', option)}
                                        suffixIcon={
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                {
                                                    requirementsSearchedContact
                                                    &&
                                                    <CopyOutlined onClick={() => handleCopy('Contact')}
                                                                  style={{fontSize: 16}}/>
                                                }
                                            </div>
                                        }
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <div className={'notificationsSelect reqsSearchInput'} onWheel={(e: any) => {
                                        if (document.activeElement?.tagName === 'INPUT') {
                                            e.preventDefault(); // Prevent scroll from triggering dropdown open when input is focused
                                        }
                                    }}>
                                        <label>Contacts</label>
                                        <Select
                                            filterOption={(input, option) => {
                                                const inputParts = input.toLowerCase().split('%').filter(Boolean);
                                                return inputParts.every(part => (option?.label as string)?.toLowerCase().includes(part));
                                            }}
                                            showSearch
                                            placeholder="Enter contact"
                                            optionFilterProp="children"
                                            // onDeselect={(value: any) => onRemoveSelected(value)}
                                            onSelect={(value: any, option: any) => onChangeSelectValue(value, 'Contacts', option)}
                                            onSearch={onSearch}
                                            options={searchedCompaniesViaContactList}
                                            notFoundContent={null}
                                            value={searchedContact}
                                            onBlur={handleBlur}
                                            onChange={(e) => setSearchedContact(e)}
                                            // value={contactsOptions}
                                            // value={modalFormFields.contacts}
                                            style={{
                                                minHeight: '44px',
                                                width: '100%',
                                                minWidth: '100%',
                                                whiteSpace: 'nowrap',
                                            }}
                                            onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                            // onBlur={handleBlur}
                                            loading={isCompaniesLoading}
                                            suffixIcon={
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {
                                                        requirementsSearchedContact
                                                        &&
                                                        <CopyOutlined onClick={() => handleCopy('Switched contact')}
                                                                      style={{marginRight: 8}}/>
                                                    }
                                                    {!isCompaniesLoading ? (
                                                        <Tooltip
                                                            text="Please, use % in searching string, after/before the actual search value, for example Schlik%Prague in order to find contacts. You can search just by Schlik% or add city or street address of contact REF after Schlik%."
                                                            classname={'reqsSearchInputTooltip'}
                                                        >
                                                            <QuestionCircleOutlined/>
                                                        </Tooltip>
                                                    ) : (
                                                        <LoadingOutlined/>
                                                    )}
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={'notificationsSelect reqsSearchInput'}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%'
                                    }}>
                                        <button onClick={onSearchModeSwitch} className={s.switchButton} style={{
                                            top: contactWasNotFound || companyWasNotFound || currentGrid === 'Requirements/Cold Offer' ? '262px' : '224px'
                                        }}>
                                            <img src={switchImg} alt="switch"/>
                                        </button>
                                    </div>
                                    <div style={{
                                        marginTop: '20px'
                                    }} onWheel={(e: any) => {
                                        if (document.activeElement?.tagName === 'INPUT') {
                                            e.preventDefault(); // Prevent scroll from triggering dropdown open when input is focused
                                        }
                                    }}>
                                        <label>Organizations</label>
                                        <Select
                                            placeholder="Select organization"
                                            // onChange={(value, option) => onChangeSelectValue(value, 'Contacts', option)}
                                            options={searchedContactsViaCompaniesList}
                                            value={modalFormFields.organisations}
                                            style={{
                                                minHeight: '44px',
                                                width: '100%'
                                            }}
                                            // defaultValue={contactsOptions}
                                            // value={contactsOptions}
                                            // value={null}
                                            onSelect={(value: any, option: any) => onChangeSelectValue(value, 'Organizations', option)}
                                            suffixIcon={
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {
                                                        modalFormFields.organisations !== null
                                                        &&
                                                        <CopyOutlined
                                                            onClick={() => handleCopy('Switched organization')}
                                                            style={{fontSize: 16}}/>
                                                    }
                                                </div>
                                            }
                                            // disabled={maxWidthInputs || disabledInputs || currentRowValues.ACT_TYPE === 'Documentation'}
                                        />
                                    </div>
                                </div>


                            </>
                    }

                </CreationModal>
            }
        {/*<MapWithModes/>*/}
        </>

    )
        ;
};


const CustomCell = React.memo(({
                                   value,
                                   field,
                                   onReqEdit,
                                   currentGrid
                               }: any) => {
    const dispatch = useAppDispatch()
    const requirementsColdOfferRows = useAppSelector(selectRequirementsColdOffer)
    const currentRequirement = requirementsColdOfferRows.find((p: any) => Number(p.REQ_ID) === Number(value))

    if (field === 'ACTIONS') {
        return <button
            className={s.editReq}
            onClick={onReqEdit}
        >
            Edit
        </button>
    } else if (field === 'REQ_ID' && currentGrid === 'Requirements/Cold Offer') {
        const onGetRequirementLinkedProperties = (value: string) => {
            dispatch(GetColdOfferRequirementLinkedPropertiesThunk(Number(value)))
            dispatch(onSetColdOfferRequirementIsOpened({isOpened: !(currentRequirement?.isOpened), reqId: value}))
        }

        return (
            <>
                <div>{value}</div>
                <button
                    onClick={() => onGetRequirementLinkedProperties(value)}
                    className={s.morePropsBtn}
                >
                    MORE
                    <img src={surfaceArrow} alt="arrow-down"/>
                </button>
            </>
        )
    } else {
        return (

            <div>{value}</div>
        )
    }


});


const CustomRow = (props: any) => {
    const [height, setHeight] = useState(25);
    const [dragging, setDragging] = useState(false);
    const [startY, setStartY] = useState(0);
    const [startHeight, setStartHeight] = useState(0);
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const divRef = useRef(null);

    useEffect(() => {
        if (dragging) {
            document.addEventListener("mousemove", resize);
            document.addEventListener("mouseup", stopResize);
        } else {
            document.removeEventListener("mousemove", resize);
            document.removeEventListener("mouseup", stopResize);
        }
        return () => {
            document.removeEventListener("mousemove", resize);
            document.removeEventListener("mouseup", stopResize);
        };
        // eslint-disable-next-line
    }, [dragging]);

    const startResize = (e: any) => {
        setDragging(true);
        setStartY(e.clientY);
        // @ts-ignore
        setStartHeight(divRef.current.offsetHeight);
    };

    const stopResize = () => {
        setDragging(false);
    };

    const resize = (e: any) => {
        const delta = e.clientY - startY;
        setHeight(startHeight + delta);
    };

    const isInPinnedColumn = () => {
        if (divRef.current) {
            // @ts-ignore
            return divRef.current.closest('.MuiDataGrid-pinnedColumns');
        }
        return false;
    };
    const onTableRowClick = (e: any) => {
        gridDataHelper.setRowId(e.rowId)
        gridDataHelper.setRowTitle(e.row.CO_NAME)
        gridDataHelper.setReqGridDomain(e.row.DOMAIN)
        navigate('/requirements/properties')
        gridDataHelper.setReqGridRowData(e.row)
        dispatch(onSetCurrentModalTab('General'))
    }

    const {className, row, pinned, ...other} = props;

    return (
        <>
            <div
                ref={divRef}
                className="resizable-div"
                // className={`${className} custom-row`}
                style={{height: `${height}px`}}
                onDoubleClick={() => {
                    onTableRowClick(props)
                }}
                {...other}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <GridRow {...props} />
                </div>
                <div className="handle handle-bottom" onMouseDown={startResize}></div>
            </div>
            {!isInPinnedColumn() && row.isOpened && row.linkedProperties.length  ? (
                <div className={s.linkedProperties}>
                    <p className={s.linkedProperties__title}>Additional information</p>
                    <div className={s.linkedProperties__table}>
                        <div className={s.linkedProperties__tableColumn_header}>
                            Prop Id
                        </div>
                        <div className={s.linkedProperties__tableColumn_header}>
                            Addr City
                        </div>
                        <div className={s.linkedProperties__tableColumn_header}>
                            Addr Quarter
                        </div>
                        <div className={s.linkedProperties__tableColumn_header}>
                            Addr Street
                        </div>
                        <div className={s.linkedProperties__tableColumn_header}>
                            Description
                        </div>
                    </div>
                    {
                        row.linkedProperties.map((p: {
                            "ADDRESS": string | null,
                            "CITY": string | null,
                            "DESCRIPTION": string | null,
                            "QUARTER": string | null,
                            "REF": number | null
                        }) => {
                            return(
                                <div className={s.linkedProperties__table}>
                                    <div className={s.linkedProperties__tableColumn}>
                                        {p.REF}
                                    </div>
                                    <div className={s.linkedProperties__tableColumn}>
                                        {p.CITY}
                                    </div>
                                    <div className={s.linkedProperties__tableColumn}>
                                        {p.QUARTER}
                                    </div>
                                    <div className={s.linkedProperties__tableColumn}>
                                        {p.ADDRESS}
                                    </div>
                                    <div className={s.linkedProperties__tableColumn}>
                                        {p.DESCRIPTION}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>)
                :
                isInPinnedColumn() && row.isOpened && row.linkedProperties.length
                ?
                    <div className={s.linkedProperties} style={{visibility: 'hidden'}}>
                        <p className={s.linkedProperties__title} style={{maxWidth: '80px', fontSize: '1px'}}>Additional information</p>
                        <div className={s.linkedProperties__table}>
                            <div className={s.linkedProperties__tableColumn_header} style={{flex: 'initial'}}>
                                Prop Id
                            </div>
                        </div>
                        {
                            row.linkedProperties.map((p: {
                                "ADDRESS": string | null,
                                "CITY": string | null,
                                "DESCRIPTION": string | null,
                                "QUARTER": string | null,
                                "REF": number | null
                            }) => {
                                return(
                                    <div className={s.linkedProperties__table}>
                                        <div className={s.linkedProperties__tableColumn} style={{flex: 'initial'}}>
                                            {p.REF}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    null
            }
        </>

    );
};


export default memo(Requirements);




