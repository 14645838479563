import React from 'react';
import s from './PropertiesHeaderButtons.module.scss';
import {
    CheckIsPropertyBrokerageReqDataRelevantThunk,
    CheckIsPropertyReqDataRelevantThunk, GetAllPropertiesGridCurrentPropertyReq,
    GetPropertiesGridThunk, GetSurfaceGridThunk,
    onSetLocalSortModel,
    onSetPropertiesGridLoaderMessage,
    selectPropertiesGridSortingRule,
    setGridPropertiesColumns,
    setShownColumns
} from "../../../store/propertiesReducer";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import {selectUserData} from "../../../store/userReducer";
import {GetBrokerageReqsGridData, selectProperty} from "../../../store/brokerageReqsReducer";

interface PropertiesHeaderButtonsType {
    onSetFiltersShown: (event: React.MouseEvent<HTMLButtonElement>) => void
    onResetConfigs: () => void
    onSaveGridConfigs: () => void
    isDisabledSaveBtn?: boolean
    gridType?: string
    isMobile?: boolean
    resetFilters: () => void
    disabledBtns?: boolean
}


const PropertiesHeaderButtons = ({
                                     onSetFiltersShown,
                                     onResetConfigs,
                                     onSaveGridConfigs,
                                     isDisabledSaveBtn,
                                     gridType,
                                     isMobile,
                                     resetFilters,
                                     disabledBtns
                                 }: PropertiesHeaderButtonsType) => {

    const gridRowId = gridDataHelper.getRowId()
    const dispatch = useAppDispatch()
    const userData = useAppSelector(selectUserData)
    const sortingRule = useAppSelector(selectPropertiesGridSortingRule)
    const selectedBrokerageProperty = useAppSelector(selectProperty)
    const onCheckIsPropertyReqDataRelevant = () => {
        dispatch(onSetPropertiesGridLoaderMessage('Updating data is in progress'))
        dispatch(setShownColumns({columns: [], gridType: 'Properties'}))
        dispatch(setGridPropertiesColumns([]))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}))
        if (gridType === 'Properties') {
            dispatch(CheckIsPropertyReqDataRelevantThunk(gridRowId))
                .then(() => {
                    dispatch(GetPropertiesGridThunk({
                        requestData: {
                            p_agent_ref: Number(userData.user_ref),
                            p_row_req: gridRowId,
                            p_sort_order: sortingRule,
                            page: 0,
                            size: 0,
                        }
                    }))
                })
        } else if (gridType === 'Surfaces') {
            dispatch(CheckIsPropertyReqDataRelevantThunk(gridRowId))
                .then(() => {
                    dispatch(GetSurfaceGridThunk({
                        requestData: {
                            p_agent_ref: Number(userData.user_ref),
                            p_row_req: gridRowId,
                            p_sort_order: null,
                            page: 0,
                            size: 0,
                            p_prop_req: null
                        }
                    }))
                })
        } else if (gridType === 'Brokerage/Requirements') {
            dispatch(CheckIsPropertyBrokerageReqDataRelevantThunk(Number(selectedBrokerageProperty.PROP_ID!)))
                .then(() => {
                    dispatch(GetBrokerageReqsGridData({
                        p_agent_ref: Number(userData.user_ref),
                        p_prop_ref: Number(selectedBrokerageProperty.PROP_ID!),
                        p_sort_order: sortingRule
                    }))
                        .then(() => {
                            dispatch(GetAllPropertiesGridCurrentPropertyReq(String(selectedBrokerageProperty.PROP_ID!)))

                        })
                })
        }

    }

    return (
        <div className={gridType === 'Activities Grid' || gridType === 'Activities Grid/Create/Update' ? `${s.viewBtns} ${s.viewBtns_activities}` : s.viewBtns}>
            {
                !isMobile && (gridType === 'Properties' || gridType === 'Surfaces' || gridType === 'Brokerage/Requirements')
                    ?
                    <button
                        className={!isMobile ? `${s.viewBtn} ${s.viewBtn_cancel}` : `${s.viewBtn__resetMobile}`}
                        onClick={onCheckIsPropertyReqDataRelevant}
                        // disabled={isDisabledSaveBtn || disabledBtns}
                        style={{
                            marginRight: '8px',
                            marginBottom: !isMobile ? 0 : '72px'
                        }}
                    >
                        {
                            !isMobile
                            &&
                            <svg width="25" height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 style={{
                                     marginTop: '2px'
                                 }}>
                                <path
                                    d="M1.90321 7.29677C1.90321 10.341 4.11041 12.4147 6.58893 12.8439C6.87255 12.893 7.06266 13.1627 7.01355 13.4464C6.96444 13.73 6.69471 13.9201 6.41109 13.871C3.49942 13.3668 0.86084 10.9127 0.86084 7.29677C0.860839 5.76009 1.55996 4.55245 2.37639 3.63377C2.96124 2.97568 3.63034 2.44135 4.16846 2.03202L2.53205 2.03202C2.25591 2.03202 2.03205 1.80816 2.03205 1.53202C2.03205 1.25588 2.25591 1.03202 2.53205 1.03202L5.53205 1.03202C5.80819 1.03202 6.03205 1.25588 6.03205 1.53202L6.03205 4.53202C6.03205 4.80816 5.80819 5.03202 5.53205 5.03202C5.25591 5.03202 5.03205 4.80816 5.03205 4.53202L5.03205 2.68645L5.03054 2.68759L5.03045 2.68766L5.03044 2.68767L5.03043 2.68767C4.45896 3.11868 3.76059 3.64538 3.15554 4.3262C2.44102 5.13021 1.90321 6.10154 1.90321 7.29677ZM13.0109 7.70321C13.0109 4.69115 10.8505 2.6296 8.40384 2.17029C8.12093 2.11718 7.93465 1.84479 7.98776 1.56188C8.04087 1.27898 8.31326 1.0927 8.59616 1.14581C11.4704 1.68541 14.0532 4.12605 14.0532 7.70321C14.0532 9.23988 13.3541 10.4475 12.5377 11.3662C11.9528 12.0243 11.2837 12.5586 10.7456 12.968L12.3821 12.968C12.6582 12.968 12.8821 13.1918 12.8821 13.468C12.8821 13.7441 12.6582 13.968 12.3821 13.968L9.38205 13.968C9.10591 13.968 8.88205 13.7441 8.88205 13.468L8.88205 10.468C8.88205 10.1918 9.10591 9.96796 9.38205 9.96796C9.65819 9.96796 9.88205 10.1918 9.88205 10.468L9.88205 12.3135L9.88362 12.3123C10.4551 11.8813 11.1535 11.3546 11.7585 10.6738C12.4731 9.86976 13.0109 8.89844 13.0109 7.70321Z"
                                    fill="#101828"
                                    stroke="#101828" strokeWidth="0.2" strokeLinecap="round"
                                    strokeLinejoin="round"
                                />

                            </svg>
                        }
                        Match
                    </button>
                    :
                    <div></div>
            }
            {
                !isMobile
                &&
                <button
                    className={`${s.viewBtn} ${s.viewBtn_cancel}`}
                    style={{
                        marginRight: '8px'
                    }}
                    onClick={onSetFiltersShown}
                    disabled={disabledBtns}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 2.5V17.5M10 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H10V2.5ZM10 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98816C2.67559 3.30072 2.5 3.72464 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H10V2.5Z"
                            stroke="#101828" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
                    View Fields
                </button>
            }
            <button
                className={!isMobile ? `${s.viewBtn} ${s.viewBtn_cancel}` : `${s.viewBtn__resetMobile}`}
                onClick={onResetConfigs}
                disabled={disabledBtns}
                style={{
                    marginRight: '8px',
                    marginTop: gridType === 'Activities Grid' ? 0 : 0,
                    marginBottom: !isMobile ? 0 : '12px'
                }}
            >
                {
                    !isMobile
                    &&
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.833008 2.33337V7.33337M0.833008 7.33337H5.83301M0.833008 7.33337L4.69967 3.70004C5.85043 2.55122 7.34338 1.80751 8.95357 1.58097C10.5638 1.35442 12.204 1.65732 13.627 2.44401C15.0501 3.2307 16.179 4.45857 16.8435 5.94262C17.5081 7.42667 17.6723 9.08649 17.3115 10.672C16.9507 12.2575 16.0844 13.6828 14.8431 14.7332C13.6018 15.7835 12.0528 16.402 10.4294 16.4954C8.80608 16.5889 7.19632 16.1522 5.84272 15.2512C4.48912 14.3502 3.465 13.0337 2.92467 11.5"
                            stroke="#101828" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
                }
                Reset view
            </button>
            {
                isMobile
                &&
                <button
                    className={s.viewBtn__resetFilters}
                    onClick={resetFilters}
                    disabled={disabledBtns}
                >
                    Reset filters
                </button>
            }

            <button
                className={!isMobile ? `${s.viewBtn} ${s.viewBtn_cancel}` : `${s.viewBtn__saveMobile}`}
                onClick={onSaveGridConfigs}
                disabled={isDisabledSaveBtn || disabledBtns}
                style={{
                    marginTop: gridType === 'Activities Grid' ? 0 : 0
                }}
            >
                {
                    !isMobile
                    &&
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.1667 17.5V10.8333H5.83333V17.5M5.83333 2.5V6.66667H12.5M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
                            stroke="#101828" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
                }
                Save view
            </button>


        </div>
    );
};

export default PropertiesHeaderButtons;
