import React, {useEffect, useRef, useState} from 'react';
import s from './NotificationItem.module.scss'
import {ReactComponent as NotificationsLine} from './../../../img/icons/notifications_line.svg'
import {ReactComponent as NotificationsFlag} from './../../../img/icons/notifications_flag.svg'
import moment, {Moment} from "moment";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    ActivityEventType,
    GetEventsByActivityId,
    GetEventsById,
    GetEventsByStatus,
    onDefaultNotificationDatePickerOpened, onHeaderNotificationDatePickerOpened,
    onNotificationDatePickerOpened, onOpenContactsModal, onOpenDomainModal,
    onSetCurrentEvent,
    onSetCurrentEventId, onSetDefaultNotificationWhenEventWithNoActivityRef,
    onSetIsDataLoading,
    selectCurrentEventId, selectDatesRange,
    selectHeaderNotificationDatePickerOpened,
    selectNotificationDatePickerOpened,
    UpdateEventStatus
} from "../../../store/eventsReducer";
import NotificationsActions from "../NotificationsActions/NotificationsActions";
import {selectUserData} from "../../../store/userReducer";
import {ReactComponent as More} from "../../../img/icons/notifications_threeDots.svg";
import {DatePicker} from "rsuite";
import {FacebookCircularProgress} from "../../AllPropertiesAddressForm/AllPropertiesAddressForm";

type Handler = (event: MouseEvent | TouchEvent) => void;

type NotificationItemType = {
    status: string
    validTill: any
    activityNumber: number | string
    eventNumber?: number | string
    withActions: boolean
    eventStatus: string
    headerNotificationItem?: boolean
    isRead: number,
    description: string | null
    isLoading: boolean
    eventDescription: string | null
    event: ActivityEventType
    eventComment: string | null
}

const NotificationItem = ({
                              status,
                              activityNumber,
                              validTill,
                              withActions,
                              eventNumber,
                              eventStatus,
                              headerNotificationItem,
                              isRead,
                              description,
                              isLoading,
                              eventDescription,
                              event,
                              eventComment
                          }: NotificationItemType) => {
    const dispatch = useAppDispatch()
    const [openedMenu, setOpenedMenu] = useState(false)
    const outsideDivRef = useRef<any>(null);
    const userData = useAppSelector(selectUserData)
    // const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
    const isDatePickerOpened = useAppSelector(selectNotificationDatePickerOpened)
    const isHeaderDatePickerOpened = useAppSelector(selectHeaderNotificationDatePickerOpened)
    const currentEventId = useAppSelector(selectCurrentEventId)
    const [selectedDate, setSelectedDate] = useState(null);
    const selectedDateRange = useAppSelector(selectDatesRange)
    const onGetCurrentActivity = () => {
        // dispatch(onNotificationDatePickerOpened(false))
        // dispatch(onHeaderNotificationDatePickerOpened(false))
        console.log(activityNumber)
        console.log(headerNotificationItem)
        console.log(withActions)
        if (activityNumber !== null && !headerNotificationItem && withActions) {
            dispatch(GetEventsByActivityId(Number(activityNumber)))
            dispatch(GetEventsById(Number(eventNumber)))
        } else {
            dispatch(onSetCurrentEvent(null))
            dispatch(onSetDefaultNotificationWhenEventWithNoActivityRef(Number(eventNumber)))
        }
        dispatch(onSetCurrentEventId(Number(eventNumber)))


        // dispatch(onDefaultNotificationDatePickerOpened(false))
        if (isRead === 0) {
            onChangeIsReadStatus()
        }

    }

    const ref = useRef<HTMLDivElement>(null);
    const onOpenActionMenu = () => {
        setOpenedMenu(!openedMenu)
    }

    const onUpdateEventStatus = (status: string) => {
        const startDate = new Date(selectedDateRange[0]);
        const endDate = new Date(selectedDateRange[1]);
        if (status !== 'edit data' && status !== 'edit domain' && status !== 'edit contact') {
            // dispatch(onSetIsDataLoading(true))
            // dispatch(onChangeEventStatus({event_id: Number(eventNumber), status: status}))
            dispatch(UpdateEventStatus({
                eventId: Number(eventNumber),
                requestData: {
                    action_date: moment(validTill),
                    event_status: {eventStatus: status, description: description},
                    is_read: isRead
                }
            }))
                .then(() => {
                    if (eventStatus !== 'All Notifications') {
                        dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: [eventStatus], date_from: startDate, date_to: endDate}))
                            // .then(() => {
                            //     dispatch(onSetIsDataLoading(false))
                            // })
                            .then(() => {
                                dispatch(GetEventsById(Number(eventNumber)))
                            })
                    } else {
                        dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: [], date_from: startDate, date_to: endDate}))
                        // .then(() => {
                        //     dispatch(onSetIsDataLoading(false))
                        // })

                    }
                })
                .then(() => {
                    dispatch(GetEventsById(Number(eventNumber)))
                })
                .then(() => {
                    setOpenedMenu(false)
                })
        } else if (status === 'edit data') {
            if (!headerNotificationItem) {
                dispatch(onNotificationDatePickerOpened(true))
            } else {
                dispatch(onHeaderNotificationDatePickerOpened(true))
            }
        } else if (status === 'edit contact') {
            dispatch(onOpenContactsModal(true))
        } else if (status === 'edit domain') {
            dispatch(onOpenDomainModal(true))
        }

    }

    // useEffect for tracking outside the reset Filters/Sorting click to close it , when user click outside this block
    useEffect(() => {
        const closeDivOutsideClick = (event: MouseEvent) => {
            if (outsideDivRef.current && !outsideDivRef.current.contains(event.target as Node)) {
                setOpenedMenu(false)
            }
        };

        document.addEventListener('click', closeDivOutsideClick);
        return () => {
            document.removeEventListener('click', closeDivOutsideClick);
        };
    }, []);


    const onOpenHeaderMenu = () => {
        dispatch(onNotificationDatePickerOpened(false))
        setOpenedMenu(true)

    }

    const onDateChange = () => {
        const startDate = new Date(selectedDateRange[0]);
        const endDate = new Date(selectedDateRange[1]);
        if (!headerNotificationItem) {
            dispatch(onNotificationDatePickerOpened(false))
        } else {
            dispatch(onHeaderNotificationDatePickerOpened(false))
        }
        if (selectedDate) {
            // dispatch(onNotificationDatePickerOpened(false))
            console.log(selectedDate)
            dispatch(onSetIsDataLoading(true))
            dispatch(UpdateEventStatus({
                eventId: Number(eventNumber),
                requestData: {
                    action_date: moment(selectedDate).utc().startOf('day').toISOString(),
                    event_status: {eventStatus: status, description: description},
                    is_read: isRead
                }
            }))
                .then(() => {
                    if (eventStatus !== 'All Notifications') {
                        dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: [eventStatus], date_from: startDate, date_to: endDate}))
                            .then(() => {
                                dispatch(onSetIsDataLoading(false))
                            })
                    } else {
                        dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: [], date_from: startDate, date_to: endDate}))
                            .then(() => {
                                dispatch(onSetIsDataLoading(false))
                            })
                    }
                })
                .then(() => {
                    dispatch(GetEventsById(Number(eventNumber)))
                })
        } else {
            console.warn('Please select a date');
        }

    }
    const handleDateSelect = (date: any) => {
        setSelectedDate(date);
    };


    const onChangeIsReadStatus = () => {
        const startDate = new Date(selectedDateRange[0]);
        const endDate = new Date(selectedDateRange[1]);
        dispatch(UpdateEventStatus({
            eventId: Number(eventNumber),
            requestData: {
                action_date: moment(validTill),
                event_status: {eventStatus: status, description: description},
                is_read: 1
            }
        }))
            .then(() => {
                dispatch(GetEventsById(Number(eventNumber)))
            })
            .then(() => {
                if (eventStatus !== 'All Notifications') {
                    dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: [eventStatus], date_from: startDate, date_to: endDate}))
                } else {
                    dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: [], date_from: startDate, date_to: endDate}))
                }
            })


    }

    const onDatePickerClose = () => {
        dispatch(onNotificationDatePickerOpened(false))
        dispatch(onHeaderNotificationDatePickerOpened(false))
        dispatch(onDefaultNotificationDatePickerOpened(false))
    }

    const predefinedBottomRanges = [
        {
            label: 'Close',
            value: () => onDatePickerClose
        },
    ];


    // useEffect for tracking outside the filters modal click to close it , when user click outside the filters modal
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);
    // Function for tracking outside the filters modal click to close it , when user click outside the filters modal
    const handleClickOutside: Handler = (event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            // Check if the target is within an antd DatePicker dropdown, doesn't close it , because the Datepicker creates a portal which renders its dropdown in a different part of the DOM tree, not as a child of your div to which you've attached the ref.
            if ((event.target as HTMLElement).closest('.rs-picker-menu')) {
                return;
            }
            const selectEl = ref.current.querySelector(".rs-picker-menu");
            if (!selectEl || (selectEl && !selectEl.contains(event.target as Node))) {
                dispatch(onNotificationDatePickerOpened(false))
                dispatch(onHeaderNotificationDatePickerOpened(false))
                dispatch(onDefaultNotificationDatePickerOpened(false))
            }
        }
    };

    const parsedMoment: Moment = validTill ? moment(validTill) : moment();
    const defaultValue: Date = parsedMoment.toDate();

    return (
        <div
            className={
                status === 'action_required' && withActions
                    ? `${s.item} ${s.item_action_required} notificationItem`
                    : status === 'hold' && withActions
                        ? `${s.item} ${s.item_hold} notificationItem`
                        : status === 'confirmed' && withActions
                            ? `${s.item} ${s.item_confirmed} notificationItem`
                            : status === 'cancelled' && withActions
                                ? `${s.item} ${s.item_cancelled} notificationItem`
                                : status === 'sent' && withActions
                                    ? `${s.item} ${s.item_sent} notificationItem`
                                    : `${s.item} ${s.item_modal} notificationItem`
            }
            onClick={onGetCurrentActivity}
            style={{
                marginTop: headerNotificationItem ? '0' : '6px',
                boxShadow: currentEventId === eventNumber ? '0 2px 4px 0 rgba(0, 0, 0, 0.12)' : 'none',
                borderColor: currentEventId === eventNumber && status === 'action_required' ? '#0707FA'
                    : currentEventId === eventNumber && status === 'hold' ? '#FFF494'
                        : currentEventId === eventNumber && status === 'confirmed' ? '#12B76A'
                            : currentEventId === eventNumber && status === 'cancelled' ? '#FBB'
                                : currentEventId === eventNumber && status === 'sent' ? '#A0A0A0'
                                    : 'transparent'
            }}
            ref={ref}
        >
            {
                headerNotificationItem
                &&
                <div className={
                    status === 'action_required'
                        ? `${s.item__before} ${s.item__before_required}`
                        : status === 'hold'
                            ? `${s.item__before} ${s.item__before_hold}`
                            : status === 'confirmed'
                                ? `${s.item__before} ${s.item__before_confirmed}`
                                : status === 'cancelled'
                                    ? `${s.item__before} ${s.item__before_cancelled}`
                                    : status === 'sent'
                                        ? `${s.item__before} ${s.item__before_sent}`
                                        : `${s.item__before} ${s.item__before_modal}`
                }></div>
            }
            <div className={s.item__top}>
                <div onClick={onChangeIsReadStatus}>
                    {
                        isRead === 0
                            ?
                            <div style={{
                                marginRight: '10px',
                                marginTop: '-13px'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                     fill="none">
                                    <circle cx="4" cy="4" r="3.5" fill="#90CDF4" stroke="#4299E1"/>
                                </svg>
                            </div>
                            :
                            null
                    }
                </div>
                <p
                    className={
                        status === 'action_required'
                            ? `${s.item__priority} ${s.item__priority_required}`
                            : status === 'hold'
                                ? `${s.item__priority} ${s.item__priority_hold}`
                                : status === 'confirmed'
                                    ? `${s.item__priority} ${s.item__priority_confirmed}`
                                    : status === 'cancelled'
                                        ? `${s.item__priority} ${s.item__priority_cancelled}`
                                        : status === 'sent'
                                            ? `${s.item__priority} ${s.item__priority_sent}`
                                            : `${s.item__priority} ${s.item__priority_modal}`
                    }

                >{status ? status === 'no_action' ? '' : status !== null && status === 'action_required' ? 'Required action' : status !== null && status?.charAt(0).toUpperCase() + status?.slice(1) : ''}</p>
                <NotificationsLine/>
                <p className={s.validTill}>The date of email sending {validTill !== null ? moment(validTill).format('DD.MM.YYYY') : ''}</p>
            </div>
            <div className={s.item__bottom}>
                <NotificationsFlag/>
                {
                    eventDescription !== null
                        ?
                        <p className={s.reminder} style={{
                            width: withActions ? 'auto' : headerNotificationItem ? '350px' : '100%'
                        }}>{eventDescription}</p>
                        :
                        <p className={s.reminder} style={{
                            width: withActions ? 'auto' : headerNotificationItem ? '350px' : '100%'
                        }}>Reminder requiring action about the activity <span>{activityNumber}</span></p>
                }

                {
                    headerNotificationItem
                    &&
                    <div
                        onClick={onOpenHeaderMenu}
                        className={s.more}
                        ref={outsideDivRef}
                        style={{
                            marginLeft: '20px',
                            alignSelf: 'flex-start',
                            cursor: 'pointer'
                        }}
                    >
                        {
                            status !== 'sent' && event.event_status !== null && event.event_status.isInfo !== 1
                            &&
                            <More/>
                        }

                        {
                            openedMenu
                            &&
                            <NotificationsActions
                                onChangeStatus={onUpdateEventStatus}
                                isDefaultNotification={false}
                                isHeaderNotification={true}
                                currentEventStatus={status}
                                event={event}
                            />
                        }
                    </div>
                }
            </div>
            {
                headerNotificationItem
                    ?
                    isHeaderDatePickerOpened && currentEventId === eventNumber
                    &&
                    <>
                        {/* @ts-ignore */}
                        <DatePicker defaultValue={defaultValue} open={isHeaderDatePickerOpened && currentEventId === eventNumber} appearance={'subtle'} onChangeCalendarDate={(value) => handleDateSelect(value)} placement={'rightStart'} onOk={(date, event) => onDateChange(date, event)} ranges={predefinedBottomRanges}

                        />
                    </>
                    :
                    isDatePickerOpened && currentEventId === eventNumber
                    &&
                    <>
                        {/* @ts-ignore */}
                        <DatePicker defaultValue={defaultValue} open={isDatePickerOpened && currentEventId === eventNumber} appearance={'subtle'} onChangeCalendarDate={(value) => handleDateSelect(value)} placement={'rightStart'} onOk={(date, event) => onDateChange(date, event)} ranges={predefinedBottomRanges}

                        />
                    </>

            }
            {isLoading && currentEventId === eventNumber && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1000,
                    }}
                >
                    <FacebookCircularProgress size={30}/>
                </div>
            )}
            {
                withActions && status !== 'sent' && event.event_status !== null && event.event_status.isInfo !== 1
                &&
                <button className={s.actionsBtn} onClick={() => onOpenActionMenu()} ref={outsideDivRef}>Actions</button>
            }
            {
                openedMenu && !headerNotificationItem
                &&
                <NotificationsActions onChangeStatus={onUpdateEventStatus} isDefaultNotification={false}
                                      currentEventStatus={status} event={event}/>
            }

            {
                eventComment
                &&
                <div className={s.comment} style={{
                    maxWidth: headerNotificationItem ? '415px' : 'auto'
                }}>
                    <span>Comment:</span>
                    <span>{eventComment}</span>
                </div>
            }
        </div>
    );
};

export default NotificationItem;