import React, {useState} from 'react';
import s from "../Sidebar/Sidebar.module.scss";
import {NavLink} from "react-router-dom";
import {onChangeCurrentGridTab, onSetIsBrokerageDataUpdating} from "../../store/propertiesReducer";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {AvailableUserMenuItem, selectAvailableMenuItems, SignOutThunk} from "../../store/userReducer";
import {setOpenedBurgerMenu} from "../../store/utilityReducer";
import classes from "../common/Header/Header.module.css";
import {ReactComponent as LogOut} from "../../img/icons/logOut.svg";

const MobileBurgerMenu = () => {
    const currentPath = window.location.pathname
    const dispatch = useAppDispatch()
    const availableMenuItems = useAppSelector(selectAvailableMenuItems)
    const propertiesMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.Properties')
    const reportsMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.Reports')
    const auditMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.Audit')
    const parametersMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.Parameters')
    const contactPropertiesMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.PropertyContacts')
    const [isControlPanelOpened, setIsControlPanelOpened] = useState<boolean>(false)

    const onMenuItemClick = () => {
        dispatch(onSetIsBrokerageDataUpdating(false))
        if (currentPath === '/properties') {
            dispatch(onChangeCurrentGridTab('All Properties/REQ'))
        }
        dispatch(setOpenedBurgerMenu(false))
    }


    const onOpenNestedMenu = () => {
        dispatch(onSetIsBrokerageDataUpdating(false))
        setIsControlPanelOpened(!isControlPanelOpened)

    }


    return (
        <div className={s.mobileMenuInner}>
            <div
                className={currentPath === '/notifications' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                onClick={onMenuItemClick}
                style={{
                    borderRadius: '0px'
                }}
            >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9 22.5V12.5H15V22.5M3 9.5L12 2.5L21 9.5V20.5C21 21.0304 20.7893 21.5391 20.4142 21.9142C20.0391 22.2893 19.5304 22.5 19 22.5H5C4.46957 22.5 3.96086 22.2893 3.58579 21.9142C3.21071 21.5391 3 21.0304 3 20.5V9.5Z"
                        stroke={currentPath === '/notifications' ? '#fff' : "#667085"} strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"/>
                </svg>
                <NavLink
                    aria-disabled={true}
                    to={'/notifications'}
                >
                    Dashboard
                </NavLink>

            </div>
            <div
                className={
                    currentPath === '/requirements'
                    || currentPath === '/requirements/create-req'
                    || currentPath === '/requirements/properties'
                    || currentPath === '/reqs-searched-by-contacts'
                    || currentPath === '/requirements/create-req-contact'
                    || currentPath === '/requirements/edit-req/:id'
                        ? `${s.menu__item} ${s.menu__item_active}`
                        : s.menu__item
                }
                style={{
                    borderRadius: '0px'
                }}
                onClick={onMenuItemClick}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 20V10M12 20V4M6 20V14"
                          stroke={
                              currentPath === '/requirements'
                              || currentPath === '/requirements/create-req'
                              || currentPath === '/requirements/properties'
                              || currentPath === '/reqs-searched-by-contacts'
                              || currentPath === '/requirements/create-req-contact'
                              || currentPath === '/requirements/edit-req/:id' ? '#fff' : "#667085"}
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <NavLink
                    to={'/requirements'}
                >
                    Requirements
                </NavLink>
            </div>
            {
                propertiesMenuItem
                &&
                propertiesMenuItem?.IS_ACCESS === 1
                &&
                <div
                    className={currentPath === '/properties' || currentPath === '/properties/brokerage/reqs' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                    onClick={onMenuItemClick}
                    style={{
                        borderRadius: '0px'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 17L12 22L22 17M2 12L12 17L22 12M12 2L2 7L12 12L22 7L12 2Z"
                              stroke={currentPath === '/properties' || currentPath === '/properties/brokerage/reqs' ? '#fff' : "#667085"}
                              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                    <NavLink
                        className={(navData) => (navData.isActive ? `${s.link} ${s.link_active}` : s.link)}
                        to={'/properties'}
                    >
                        Properties
                    </NavLink>


                </div>
            }
            {
                contactPropertiesMenuItem
                &&
                contactPropertiesMenuItem?.IS_ACCESS === 1
                &&
                <div
                    className={currentPath === '/contactProperties' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                    onClick={onMenuItemClick}
                    style={{
                        borderRadius: '0px'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9 6.74952V14.9995M15 8.99952V17.2495M15.503 20.7475L20.378 18.3105C20.759 18.1205 21 17.7305 21 17.3045V4.81952C21 3.98352 20.12 3.43952 19.372 3.81352L15.503 5.74752C15.186 5.90652 14.813 5.90652 14.497 5.74752L9.503 3.25152C9.34682 3.17346 9.17461 3.13281 9 3.13281C8.82539 3.13281 8.65318 3.17346 8.497 3.25152L3.622 5.68852C3.24 5.87952 3 6.26952 3 6.69452V19.1795C3 20.0155 3.88 20.5595 4.628 20.1855L8.497 18.2515C8.814 18.0925 9.187 18.0925 9.503 18.2515L14.497 20.7485C14.814 20.9065 15.187 20.9065 15.503 20.7485V20.7475Z"
                            stroke={currentPath === '/contactProperties' ? '#fff' : "#667085"} strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
                    <NavLink
                        className={(navData) => (navData.isActive ? `${s.link} ${s.link_active}` : s.link)}
                        to={'/contactProperties'}
                    >
                        Properties Contacts
                    </NavLink>
                </div>
            }
            <div
                className={currentPath === '/activitiesGrid' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                onClick={onMenuItemClick}
                style={{
                    borderRadius: '0px'
                }}
            >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g id="eva:activity-fill">
                        <g id="evaActivityFill0">
                            <g id="evaActivityFill1">
                                <path id="evaActivityFill2"
                                      d="M14.33 20.5002H14.12C13.6968 20.4582 13.2979 20.2824 12.9814 19.9983C12.6649 19.7141 12.4472 19.3365 12.36 18.9202L9.68 6.50023L6.92 12.9002C6.84193 13.0791 6.71323 13.2312 6.54975 13.3378C6.38627 13.4445 6.19517 13.5009 6 13.5002H3C2.73478 13.5002 2.48043 13.3949 2.29289 13.2073C2.10536 13.0198 2 12.7654 2 12.5002C2 12.235 2.10536 11.9807 2.29289 11.7931C2.48043 11.6056 2.73478 11.5002 3 11.5002H5.34L7.85 5.71023C8.01896 5.32169 8.30695 4.99685 8.67244 4.78254C9.03793 4.56823 9.46203 4.47552 9.88361 4.51779C10.3052 4.56006 10.7024 4.73512 11.0181 5.01772C11.3338 5.30033 11.5515 5.67588 11.64 6.09023L14.32 18.5002L17.08 12.1202C17.155 11.9376 17.2824 11.7813 17.4461 11.6709C17.6099 11.5606 17.8026 11.5012 18 11.5002H21C21.2652 11.5002 21.5196 11.6056 21.7071 11.7931C21.8946 11.9807 22 12.235 22 12.5002C22 12.7654 21.8946 13.0198 21.7071 13.2073C21.5196 13.3949 21.2652 13.5002 21 13.5002H18.66L16.15 19.2902C15.9967 19.6467 15.7431 19.951 15.4199 20.1658C15.0967 20.3807 14.718 20.4969 14.33 20.5002Z"
                                      fill={currentPath === '/activitiesGrid' || currentPath === '/organizationActivities' || currentPath === '/organizationActivitiesProperties' ? '#fff' : "#667085"}/>
                            </g>
                        </g>
                    </g>
                </svg>

                <NavLink
                    to={'/activitiesGrid'}
                >
                    Activities
                </NavLink>
            </div>
            {reportsMenuItem
                &&
                reportsMenuItem?.IS_ACCESS === 1
                &&
                <div
                    onClick={onOpenNestedMenu}
                    className={s.menu__item}
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        borderRadius: '0px'
                    }}
                >
                    <div style={{
                        display: 'flex'
                    }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 11L12 14L22 4M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16"
                                stroke={"#667085"} strokeWidth="2"
                                strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                        <div >
                            <p>Control Panel</p>
                            <svg style={{
                                transform: isControlPanelOpened ? 'rotate(180deg)' : 'none'
                            }
                            } className={s.menu__item_arrow} width="20" height="20" viewBox="0 0 20 20"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 7.5L10 12.5L15 7.5" stroke="#98A2B3" strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    {isControlPanelOpened
                        &&
                        <div className={s.nestedMenu} style={{
                            display: 'flex'
                        }}>
                            <svg width="1" height="82" viewBox="0 0 1 82" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="1" height="82" fill="#667085"/>
                            </svg>
                            <div>
                                <div
                                    className={currentPath === '/control-panel'
                                    || currentPath === '/control-panel/activity-report'
                                    || currentPath === '/control-panel/agent-last-contact'
                                    || currentPath === '/control-panel/property-last-contact'
                                    || currentPath === '/control-panel/requirement-last-contact'
                                    || currentPath === '/control-panel/valuated-properties-last-contact'
                                        ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                    onClick={onMenuItemClick}
                                    style={{
                                        borderRadius: '0px'
                                    }}
                                >
                                    <NavLink
                                        to={'/control-panel'}
                                    >
                                        Activity Reports
                                    </NavLink>
                                </div>
                                <div
                                    className={currentPath === '/fraud-reports' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                    onClick={onMenuItemClick}
                                    style={{
                                        borderRadius: '0px'
                                    }}
                                >
                                    <NavLink
                                        to={'/fraud-reports'}
                                    >
                                        Fraud Reports
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            {auditMenuItem
                &&
                auditMenuItem.IS_ACCESS === 1
                &&
                <div
                    className={currentPath === '/audit' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                    onClick={onMenuItemClick}
                    style={{
                        borderRadius: '0px'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.382 5.968C7.97574 4.69107 9.9578 3.99674 12 4C14.125 4 16.078 4.736 17.618 5.968L19.071 4.515L20.485 5.929L19.032 7.382C19.9292 8.50526 20.5443 9.82713 20.8258 11.2369C21.1073 12.6467 21.0469 14.1034 20.6498 15.4851C20.2527 16.8668 19.5304 18.1333 18.5433 19.1785C17.5562 20.2237 16.3331 21.0172 14.9764 21.4926C13.6196 21.9681 12.1687 22.1116 10.7452 21.9111C9.32158 21.7107 7.96672 21.1721 6.79402 20.3405C5.62132 19.509 4.66489 18.4085 4.00479 17.1314C3.3447 15.8543 3.00016 14.4376 3 13C3 10.875 3.736 8.922 4.968 7.382L3.515 5.93L4.929 4.516L6.382 5.968ZM12 20C12.9193 20 13.8295 19.8189 14.6788 19.4672C15.5281 19.1154 16.2997 18.5998 16.9497 17.9497C17.5998 17.2997 18.1154 16.5281 18.4672 15.6788C18.8189 14.8295 19 13.9193 19 13C19 12.0807 18.8189 11.1705 18.4672 10.3212C18.1154 9.47194 17.5998 8.70026 16.9497 8.05025C16.2997 7.40024 15.5281 6.88463 14.6788 6.53284C13.8295 6.18106 12.9193 6 12 6C10.1435 6 8.36301 6.7375 7.05025 8.05025C5.7375 9.36301 5 11.1435 5 13C5 14.8565 5.7375 16.637 7.05025 17.9497C8.36301 19.2625 10.1435 20 12 20ZM13 12H16L11 18.5V14H8L13 7.495V12ZM8 1H16V3H8V1Z"
                            fill={currentPath === '/audit' ? '#fff' : "#667085"}/>
                    </svg>
                    <NavLink
                        to={'/audit'}
                    >
                        Audit
                    </NavLink>
                </div>
            }
            {parametersMenuItem
                &&
                parametersMenuItem.IS_ACCESS === 1
                &&
                <div
                    className={currentPath === '/parameters'
                    || currentPath === '/parameters/marketing-reports'
                    || currentPath === '/parameters/disclaimer-info'
                    || currentPath === '/parameters/templates-for-email'
                    || currentPath === '/parameters/templates-for-email/email-template'
                    || currentPath === '/parameters/create-activity-codes'
                    || currentPath === '/parameters/report-agents-order'
                    || currentPath === '/parameters/administration'
                        ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                    onClick={onMenuItemClick}
                    style={{
                        borderRadius: '0px'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22"
                            stroke={currentPath === '/parameters'
                            || currentPath === '/parameters/marketing-reports'
                            || currentPath === '/parameters/disclaimer-info'
                            || currentPath === '/parameters/templates-for-email'
                            || currentPath === '/parameters/create-activity-codes'
                            || currentPath === '/parameters/report-agents-order'
                            || currentPath === '/parameters/administration'
                            || currentPath === '/parameters/templates-for-email/email-template' ? '#fff' : "#667085"}
                            strokeWidth="2"
                            strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <NavLink
                        to={'/parameters'}

                    >
                        Parameters
                    </NavLink>
                </div>
            }
            <div className={s.menu__item} onClick={() => dispatch(SignOutThunk())}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 2H18C19.1 2 20 2.9 20 4V20C20 21.1 19.1 22 18 22H9C7.9 22 7 21.1 7 20V18H9V20H18V4H9V6H7V4C7 2.9 7.9 2 9 2Z" fill="#667085"/>
                    <path d="M10.09 15.59L11.5 17L16.5 12L11.5 7L10.09 8.41L12.67 11H3V13H12.67L10.09 15.59Z" fill="#667085"/>
                </svg>
                <p>Log Out</p>
            </div>
        </div>
    );
};

export default MobileBurgerMenu;