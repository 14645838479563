import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    GetContactsPropertyOwners,
    GetDefaultRequirementForMapOfferThunk,
    GetMapPropertiesStatusesThunk,
    GetMapPropertiesThunk,
    GetMapSimilarPropertiesThunk,
    onClearPropertyOwners,
    onSetCurrentUserDomain,
    onSetMapProperties,
    onSetMapZoomCoordinates, onSetPropertyMapRowHeight, onSetSelectedPropertyId,
    selectContactOwners,
    selectCurrentUserDomain,
    selectDefaultReqForLinks,
    selectIsDataLoading,
    selectMapDataPropertiesNotFound,
    selectMapProperties,
    selectMapPropertiesStatuses,
    selectMapPropertiesZoomCoordinates, selectSelectedPropertyId,
    selectSimilarMapProperties,
} from "../../store/propertiesMap";
import {
    Circle,
    FeatureGroup,
    MapContainer,
    Popup,
    TileLayer,
    useMap,
} from "react-leaflet";
import L from "leaflet";
import UniversalInput from "../common/UniversalInput/UniversalInput";
import styles from "../AddressForm/AddressFrom.module.scss";
import {ContactOwnerType, MapPropertyType} from "../../types/mapPropertiesTypes";
import s from './PropertiesMap.module.scss'
import {DataGridPro, GridRow, useGridApiRef} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {FacebookCircularProgress} from "../AllPropertiesAddressForm/AllPropertiesAddressForm";
import {Button, Checkbox, Empty, message, Modal, Radio, RadioChangeEvent, Select, Switch} from "antd";
import {getAbortController} from "../../helpers/AbortControllerManager";
import {QuestionCircleOutlined} from "@ant-design/icons";
import Tooltip from "../Tooltip/Tooltip";
import {
    GetPropertiesContactLang,
    GetPropertiesEmailBody,
    GetPropertiesEmailPhotosForLinks,
    MapPropertyDetails,
    onAddMapCheckedProperties,
    onChangeWindowModesMode,
    onSetCurrentWindow,
    selectMapCheckedProperties,
    selectPropContactsWindowModes,
    selectPropertyContactLang
} from "../../store/propertiesReducer";
import {selectUserData} from "../../store/userReducer";
import CreationModal from "../common/CreationModal/CreationModal";


const PropertiesMap = () => {
    const dispatch = useAppDispatch()
    const [searchInput, setSearchInput] = useState<string>("");
    // const [isLoading, setLoading] = useState<boolean>(true);
    const mapProperties = useAppSelector(selectMapProperties)
    const [searchPlace, setSearchPlace] = useState<any | null>(null);
    const [position, setPosition] = useState<L.LatLng | null>(null);
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 210);
    const mapStatuses = useAppSelector(selectMapPropertiesStatuses)
    const [mapZoom, setMapZoom] = useState(17)
    const [currentPointLatLng, setCurrentPointLatLng] = useState<any>()
    const [clickedPropertyId, setClickedPropertyId] = useState(0)
    const mapPropertiesNotFound = useAppSelector(selectMapDataPropertiesNotFound)
    const similarMapProperties = useAppSelector(selectSimilarMapProperties)
    const isLoading = useAppSelector(selectIsDataLoading)
    const [isMapMovePropsActivated, setIsMapMovePropsActivated] = useState<boolean>(false)
    const contactLang = useAppSelector(selectPropertyContactLang)
    const checkedMapProperties = useAppSelector(selectMapCheckedProperties)
    const propContactsWindowModes = useAppSelector(selectPropContactsWindowModes)
    const emailButtonMode = propContactsWindowModes?.find((m) => m.modeName === 'Email')
    const [mapBounds, setMapBounds] = useState<any>()
    const userData = useAppSelector(selectUserData)
    const [openedDomainModal, setOpenedDomainModal] = useState(false)
    const currentUserDomain = useAppSelector(selectCurrentUserDomain)
    const defaultReqForLinks = useAppSelector(selectDefaultReqForLinks)
    const [currentSpotColor, setCurrentSpotColor] = useState<undefined | string>(undefined)
    const [hoveredItem, setHoveredItem] = useState<boolean>(false);
    const [isZooming, setIsZooming] = useState(false);
    const selectedPropertyId = useAppSelector(selectSelectedPropertyId)
    useEffect(() => {
        if (userData.department === null || userData.department === 'null') {
            setOpenedDomainModal(true)
        } else {
            dispatch(onSetCurrentUserDomain(userData.department))
        }
    }, [userData.department, dispatch])


    const onCancelRequest = () => {
        const controller = getAbortController();
        controller.abort();
    }

    const getAddressInfoFromPosition = async (lat: any, lng: any) => {
        try {
            const headers = new Headers();
            headers.append('accept-language', 'cz');
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&city=Praha`,
                {headers}
            );

            if (response.ok) {
                const data = await response.json();
                if (!isMapMovePropsActivated) {
                    setSearchPlace(data);
                    // Set map position (assuming you're using a map object like Leaflet)
                    const lat = parseFloat(data.lat);  // Convert string to float
                    const lon = parseFloat(data.lon);
                    // @ts-ignore
                    setPosition([lat, lon]);

                    // Assuming you have a reference to the map object
                    // if (map) {
                    //     map.setView([lat, lon], 13);  // Adjust zoom level as needed
                    // }

                    return data;
                }
            } else {
                throw new Error('Address not found');
            }
        } catch (error) {
            console.error('Error fetching address information:', error);
        }
    };
    const mapZoomCoordinates = useAppSelector(selectMapPropertiesZoomCoordinates)
    const [mapStatus, setMapStatus] = useState<null | string>(null)
    const [selectionModel, setSelectionModel] = useState([])
    useEffect(() => {
        if (mapProperties?.length) {
            const mapPropertyPosition = mapProperties.find((p: MapPropertyType) => p.PROP_ADDR_LATI !== null && p.PROP_ADDR_LONGI !== null)
            if (mapPropertyPosition) {
                getAddressInfoFromPosition(mapPropertyPosition?.PROP_ADDR_LATI, mapPropertyPosition?.PROP_ADDR_LONGI)
            }
        }
        // eslint-disable-next-line
    }, [mapProperties])


    const selectMapStatuses = mapStatuses.map((status: any) => ({
        value: `${status.AE_NO_INDEX} `,
        label: `${status.AE_LI_VALUE}`,
    })).concat({value: `All`, label: `All`,})

    const similarMapPropertiesOptions = similarMapProperties.map((p: string) => ({
        value: p,
        label: p,
    }))

    useEffect(() => {
        // setLoading(true)
        if (currentUserDomain !== null && currentUserDomain !== 'null') {
            dispatch(GetMapPropertiesThunk({
                searchData: {
                    search_data: 'Staré Město',
                    status_index: null,
                    domain: currentUserDomain
                }
            }))
        }


        // .then(() => {
        //     setLoading(false)
        //
        // })
    }, [dispatch, currentUserDomain])

    useEffect(() => {
        dispatch(GetMapPropertiesStatusesThunk())
    }, [dispatch])

    const onSetCurrentPointLatLng = (lat: number, lng: number, propertyId: number, spotColor: string) => {
        setClickedPropertyId((prevId) => {
            // Force update even if the same property is clicked again
            if (prevId === propertyId) {
                return -1; // Reset first to force a state change
            }
            return propertyId;
        });

        setCurrentPointLatLng({lat, lng});
        setCurrentSpotColor(spotColor);
    };


    const handleCheckProperties = (value: any) => {
        console.log(value)
    }


    const formatDate = (inputDate: string, activityCount: number | null): JSX.Element => {
        const date = new Date(inputDate);

        // Formatting the date to "DD.MM.YYYY"
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '2px',
                borderBottom: '2px solid rgba(102, 112, 133, 0.60)'
            }}>
                <div className={s.dateIcon}>
                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Vector"
                              d="M6.165 8.66992H6.06C5.84838 8.64891 5.64896 8.56096 5.49071 8.41882C5.33246 8.27669 5.22362 8.08779 5.18 7.87955L3.84 1.6666L2.46 4.86812C2.42097 4.9576 2.35661 5.0337 2.27487 5.08703C2.19314 5.14036 2.09758 5.1686 2 5.16826H0.5C0.367392 5.16826 0.240215 5.11556 0.146447 5.02175C0.0526784 4.92793 0 4.8007 0 4.66802C0 4.53535 0.0526784 4.40812 0.146447 4.3143C0.240215 4.22049 0.367392 4.16779 0.5 4.16779H1.67L2.925 1.27141C3.00948 1.07705 3.15348 0.914555 3.33622 0.807349C3.51896 0.700144 3.73102 0.65377 3.9418 0.674914C4.15259 0.696058 4.35122 0.783628 4.50905 0.924998C4.66689 1.06637 4.77577 1.25423 4.82 1.4615L6.16 7.66945L7.54 4.47793C7.57751 4.38658 7.64121 4.30838 7.72307 4.25319C7.80493 4.198 7.90129 4.16828 8 4.16779H9.5C9.63261 4.16779 9.75979 4.22049 9.85355 4.3143C9.94732 4.40812 10 4.53535 10 4.66802C10 4.8007 9.94732 4.92793 9.85355 5.02175C9.75979 5.11556 9.63261 5.16826 9.5 5.16826H8.33L7.075 8.06463C6.99836 8.24298 6.87153 8.39516 6.70995 8.50263C6.54837 8.61011 6.35902 8.66823 6.165 8.66992Z"
                              fill="black"/>
                    </svg>
                </div>
                <span className={s.formattedDate}>Last activity: {formattedDate}</span>
                <svg style={{
                    marginTop: '-2px'
                }} xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                    <path d="M7.09375 1.91992L9.4375 4.26367L7.09375 6.60742" stroke="black" strokeWidth="2"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.4375 4.26367H5.6875C3.09859 4.26367 1 6.36227 1 8.95117V9.41992" stroke="black"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className={s.formattedHours}>{hours}:{minutes} {ampm}</span>
                {
                    activityCount !== 0
                        ?
                        <span className={s.formattedDate} style={{
                            marginLeft: '8px'
                        }}>Activity count: {activityCount}</span>
                        :
                        null
                }
            </div>
        );
    };


    const onHideOwnerContacts = (prop_ref: number) => {
        dispatch(onClearPropertyOwners({prop_ref: prop_ref}))
        dispatch(onSetPropertyMapRowHeight({prop_ref: prop_ref, rowHeight: 85}))
    }

    const OSMMap: React.FC<{ zoomLevel: number }> = ({zoomLevel}) => {
        const map = useMap();
        useEffect(() => {
            if (searchPlace) {
                const zoom = map.getZoom()
                const {lat, lon} = searchPlace;
                map.setView([lat, lon], zoom);
            }
            // eslint-disable-next-line
        }, [map, searchPlace]);
        // Sync external zoom changes with internal state
        useEffect(() => {
            if (map.getZoom() !== zoomLevel) {
                map.setZoom(zoomLevel);
                setMapZoom(zoomLevel);
            }
        }, [zoomLevel, map]);
        useEffect(() => {
            const handleZoomStart = () => {
                setIsZooming(true);  // Set zooming state to true
                setIsMapMovePropsActivated(false)
            };

            const handleZoomEnd = () => {
                setMapZoom(map.getZoom());
                setIsZooming(false);  // Set zooming state to false after zoom ends
            };

            map.on('moveend', handleMapMove);
            map.on('zoomstart', handleZoomStart);
            map.on('zoomend', handleZoomEnd);

            return () => {
                map.off('moveend', handleMapMove);
                map.off('zoomstart', handleZoomStart);
                map.off('zoomend', handleZoomEnd);
            };
            // eslint-disable-next-line
        }, [map]);
        useEffect(() => {
            if (map) {
                const bounds = map.getBounds();
                // Compare bounds properties directly (assuming bounds has methods like getNorthEast and getSouthWest)
                const northEast = bounds.getNorthEast();
                const southWest = bounds.getSouthWest();

                // Check if the mapBounds are different (perform a deep comparison)
                if (!mapBounds ||
                    northEast.lat !== mapBounds.getNorthEast().lat ||
                    northEast.lng !== mapBounds.getNorthEast().lng ||
                    southWest.lat !== mapBounds.getSouthWest().lat ||
                    southWest.lng !== mapBounds.getSouthWest().lng) {

                    setMapBounds(bounds);
                }
            }
            // eslint-disable-next-line
        }, [map, mapBounds]);

        // @ts-ignore
        useEffect(() => {
            if (currentPointLatLng) {
                // Find the layer with matching coordinates
                // @ts-ignore
                const c = Object.values(map._layers).find((layer) => layer._latlng?.lat === currentPointLatLng.lat && layer._latlng?.lng === currentPointLatLng.lng);
                // @ts-ignore
                if (c && c._popup) {
                    // @ts-ignore
                    c.openPopup(); // Open the popup
                }
                // @ts-ignore
                if (c?._latlng?.lat && c?._latlng?.lng) {map.setView([c._latlng.lat, c._latlng.lng], map.getZoom())}
                // Removed the state reset to prevent blocking single-click
                setTimeout(() => {
                    map.setView([currentPointLatLng.lat, currentPointLatLng.lng], map.getZoom());
                    setSelectionModel([]); // Optional: reset selection if needed
                }, 350);
                setTimeout(() => {
                    setCurrentPointLatLng(null)
                }, 400)
            }
            // @ts-ignore
            // eslint-disable-next-line
        }, [currentPointLatLng, map._layers, map]);
        let previousTopLatitude = map.getBounds().getNorth();
        let previousLeftLongitude = map.getBounds().getWest();

        const handleMapMove = () => {
            if (isZooming) return;
            setSearchPlace(null)
            const bounds = map.getBounds();
            const topLatitude = bounds.getNorth();
            const bottomLatitude = bounds.getSouth();
            const leftLongitude = bounds.getWest();
            const rightLongitude = bounds.getEast();
            // Calculate the change in coordinates from the previous bounds
            const latChange = Math.abs(topLatitude - previousTopLatitude);
            const lonChange = Math.abs(leftLongitude - previousLeftLongitude);
            // Define a threshold for what you consider a "small distance" (adjust as needed)
            const smallDistanceThreshold = 0.003; // Example threshold

                if (isMapMovePropsActivated) {
                    if (latChange > smallDistanceThreshold || lonChange > smallDistanceThreshold) {
                        // Map has moved a significant distance, so update the coordinates and make the API call
                        dispatch(
                            onSetMapZoomCoordinates({
                                lattop: bottomLatitude,
                                latbottom: topLatitude,
                                lantop: leftLongitude,
                                lanbottom: rightLongitude,
                            })
                        );
                        if (mapStatus !== null) {
                            dispatch(
                                GetMapPropertiesThunk({
                                    searchData: {
                                        zoom_request: {
                                            lattop: bottomLatitude,
                                            latbottom: topLatitude,
                                            lantop: leftLongitude,
                                            lanbottom: rightLongitude,
                                        },
                                        status_index: mapStatus !== null ? Number(mapStatus) : null,
                                        domain: currentUserDomain
                                    },
                                })
                            );
                        } else if (searchInput !== '') {
                            dispatch(GetMapSimilarPropertiesThunk({
                                search_data: searchInput,
                                status_index: mapStatus !== null ? Number(mapStatus) : null,
                                domain: currentUserDomain!!
                            }));
                            dispatch(
                                GetMapPropertiesThunk({
                                    searchData: {
                                        zoom_request: {
                                            lattop: bottomLatitude,
                                            latbottom: topLatitude,
                                            lantop: leftLongitude,
                                            lanbottom: rightLongitude,
                                        },
                                        status_index: mapStatus !== null ? Number(mapStatus) : null,
                                        // search_data: searchInput,
                                        domain: currentUserDomain
                                    },
                                })
                            );
                        } else {
                            dispatch(
                                GetMapPropertiesThunk({
                                    searchData: {
                                        zoom_request: {
                                            lattop: bottomLatitude,
                                            latbottom: topLatitude,
                                            lantop: leftLongitude,
                                            lanbottom: rightLongitude,
                                        },
                                        status_index: mapStatus !== null ? Number(mapStatus) : null,
                                        domain: currentUserDomain
                                    },
                                })
                            );
                        }

                        // Update the previous bounds for the next check
                        // @ts-ignore
                        previousTopLatitude = topLatitude;
                        // @ts-ignore
                        previousLeftLongitude = leftLongitude;
                    }
                } else {
                    setMapBounds(bounds)
                }
           

        };
        return null;

    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchInput(value);
        setSelectionModel([])
        setCurrentPointLatLng(null)
        setIsMapMovePropsActivated(false)

    };
    const debounce = (func: Function, wait: number) => {
        let timeout: ReturnType<typeof setTimeout> | null;
        return function (this: any, ...args: any[]) {
            const context = this;
            clearTimeout(timeout!);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };
    // layout effect to always adapt grid height to the user screen device height
    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage);
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const onSearch = () => {

        if (searchInput !== '') {
            // setLoading(true)
            setIsMapMovePropsActivated(false)
            dispatch(GetMapSimilarPropertiesThunk({
                search_data: searchInput,
                status_index: mapStatus !== null ? Number(mapStatus) : null,
                domain: currentUserDomain!!
            }))
            dispatch(GetMapPropertiesThunk({
                searchData: {
                    search_data: searchInput,
                    status_index: mapStatus !== null ? Number(mapStatus) : null,
                    domain: currentUserDomain
                },
                search_value: searchInput,

            }))
                .then((res) => {
                    // setLoading(false)
                    // @ts-ignore
                    if (res.payload && (res.payload === null || res.payload.length === 0)) {
                        message.warn('Properties with this address were not found')
                    }
                })
        } else {
            message.warn('Please enter the address to search properties')
        }

    }

    //check reverse api request

    const handleCircleMouseOver = (e: any) => {
        e.target.openPopup();
        setCurrentPointLatLng(null)
        setSelectionModel([])
        setSearchPlace(null)
    }

    const handleCircleMouseOut = (e: any) => {
        setSelectionModel([])
        e.target.closePopup();
        setCurrentPointLatLng(null)
        setSearchPlace(null)
    }

    const handleZoomChange = (value: string) => {
        if (value === 'Zoom 1km') {
            setMapZoom(18)
            setIsMapMovePropsActivated(true)
        } else if (value === 'Zoom 2km') {
            setMapZoom(17)
            setIsMapMovePropsActivated(true)
        } else if (value === 'Zoom 5km') {
            setMapZoom(16)
            setIsMapMovePropsActivated(true)
        } else if (value === 'Zoom 7km') {
            setMapZoom(15)
            setIsMapMovePropsActivated(true)
        }
    }

    const handleStatusChange = (value: string) => {
        setMapStatus(value)
        // setLoading(true)
        dispatch(onSetMapProperties())
        const topLatitude = mapBounds.getNorth();
        const bottomLatitude = mapBounds.getSouth();
        const leftLongitude = mapBounds.getWest();
        const rightLongitude = mapBounds.getEast();
        if (mapZoomCoordinates !== null) {
            dispatch(GetMapSimilarPropertiesThunk({
                search_data: searchInput,
                status_index: value !== null ? Number(value) : null,
                domain: currentUserDomain!!,
                zoom_request: mapZoomCoordinates,
            }))
            dispatch(GetMapPropertiesThunk({
                searchData: {
                    status_index: value !== null ? Number(value) : null,
                    zoom_request: mapZoomCoordinates,
                    domain: currentUserDomain
                }
            }))
            // .then(() => {
            //     setLoading(false)
            // })
        } else {
            dispatch(GetMapPropertiesThunk({
                searchData: {
                    status_index: value !== null ? Number(value) : null,
                    zoom_request: {
                        lattop: bottomLatitude,
                        latbottom: topLatitude,
                        lantop: leftLongitude,
                        lanbottom: rightLongitude,
                    },
                    domain: currentUserDomain
                }
            }))
            // .then(() => {
            //     setLoading(false)
            // })
        }

    }


    const onClearSearchInput = () => {
        setSearchInput('')
    }

    const handleSimilarPropertiesSelect = (value: string) => {
        setSearchInput(`${value}%`)
    }


    const onSwitchChange = (isChecked: boolean) => {
        setIsMapMovePropsActivated(isChecked)

        if (isChecked) {
            const topLatitude = mapBounds.getNorth();
            const bottomLatitude = mapBounds.getSouth();
            const leftLongitude = mapBounds.getWest();
            const rightLongitude = mapBounds.getEast();
            if (mapStatus !== null) {
                dispatch(
                    GetMapPropertiesThunk({
                        searchData: {
                            zoom_request: {
                                lattop: bottomLatitude,
                                latbottom: topLatitude,
                                lantop: leftLongitude,
                                lanbottom: rightLongitude,
                            },
                            status_index: mapStatus !== null ? Number(mapStatus) : null,
                            domain: currentUserDomain
                        },
                    })
                );
            } else if (searchInput !== '') {
                dispatch(GetMapSimilarPropertiesThunk({
                    search_data: searchInput,
                    status_index: mapStatus !== null ? Number(mapStatus) : null,
                    domain: currentUserDomain!!
                }));
                dispatch(
                    GetMapPropertiesThunk({
                        searchData: {
                            zoom_request: {
                                lattop: bottomLatitude,
                                latbottom: topLatitude,
                                lantop: leftLongitude,
                                lanbottom: rightLongitude,
                            },
                            status_index: mapStatus !== null ? Number(mapStatus) : null,
                            // search_data: searchInput,
                        },
                    })
                );
            } else {
                dispatch(
                    GetMapPropertiesThunk({
                        searchData: {
                            zoom_request: {
                                lattop: bottomLatitude,
                                latbottom: topLatitude,
                                lantop: leftLongitude,
                                lanbottom: rightLongitude,
                            },
                            status_index: mapStatus !== null ? Number(mapStatus) : null,
                            domain: currentUserDomain
                        },
                    })
                );
            }
        }

    }


    const handlePolygonClick = (p: any) => {
        if (emailButtonMode?.mode === 'Opened') {
            if (p.STATUS_INDEX === 1) {
                dispatch(onAddMapCheckedProperties({property: p}))
                dispatch(GetPropertiesEmailBody({
                    req_id: defaultReqForLinks!!,
                    gridType: 'All Properties/Upcoming',
                    lang: contactLang
                }))
                dispatch(GetPropertiesEmailPhotosForLinks({
                    p_fk_type: 'Property',
                    p_row_fk: Number(p.XREF_PROPERTY),
                    p_row_req: defaultReqForLinks!!,
                }))
            }
        } else {
            if (p.STATUS_INDEX === 1) {
                Modal.confirm({
                    title: 'Add Property to Offer',
                    icon: <QuestionCircleOutlined/>,
                    content: 'Do you want to open offer creation and add this property to an offer?',
                    okText: 'Add',
                    cancelText: 'Cancel',
                    onOk() {
                        dispatch(onSetCurrentWindow('Email'))
                        dispatch(onChangeWindowModesMode({modeName: 'Email', mode: 'Opened'}))
                        dispatch(onAddMapCheckedProperties({property: p}))
                        dispatch(GetPropertiesEmailBody({
                            req_id: defaultReqForLinks!!,
                            gridType: 'All Properties/Upcoming',
                            lang: contactLang
                        }))
                        dispatch(GetPropertiesEmailPhotosForLinks({
                            p_fk_type: 'Property',
                            p_row_fk: Number(p.XREF_PROPERTY),
                            p_row_req: defaultReqForLinks!!,
                        }))
                    },
                })
            }
        }
        dispatch(onSetSelectedPropertyId(p.XREF_PROPERTY))
        scrollToProperty(p.XREF_PROPERTY)
    }


    useEffect(() => {
        if (userData.department !== null && userData.department !== 'null') {
            dispatch(GetDefaultRequirementForMapOfferThunk(userData.department))
                .then((res) => {
                    // @ts-ignore
                    dispatch(GetPropertiesContactLang(res.payload))
                })
        }
    }, [userData.department, dispatch])

    const onDomainChoose = () => {
        setOpenedDomainModal(false)
        dispatch(GetDefaultRequirementForMapOfferThunk(currentUserDomain))
            .then((res) => {
                // @ts-ignore
                dispatch(GetPropertiesContactLang(res.payload))
            })
    }


    const handleDomainChange = (e: RadioChangeEvent) => {
        dispatch(onSetCurrentUserDomain(e.target.value))

    };

    const groupedProperties = mapProperties?.reduce((acc, prop) => {
        const key = `${prop.PROP_ADDR_LATI}-${prop.PROP_ADDR_LONGI}`;
        // @ts-ignore
        if (!acc[key]) {
            // @ts-ignore
            acc[key] = [];
        }
        // @ts-ignore
        acc[key].push(prop);
        return acc;
    }, {});

    const uniqueProperties: any[] = [];
    const groupedDuplicates: any[] = [];

    Object.values(groupedProperties ?? {}).forEach((group) => {
        if (Array.isArray(group) && group.length === 1) {
            uniqueProperties?.push(group);
        } else {
            groupedDuplicates?.push(group);
        }
    });

    const handleItemHover = () => {
        setHoveredItem(true);
    };
    const handleItemLeave = () => {
        setHoveredItem(false);
    };


    const scrollToProperty = (propertyId: number) => {
        const cardElement = document.getElementById(`${propertyId}`);
        if (cardElement) {
            cardElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            const scrollContainer = document.scrollingElement || document.documentElement;
            scrollContainer.scrollTop += 10;
        }
    };

    const onRowClick = (property: MapPropertyType) => {
        // @ts-ignore
        onSetCurrentPointLatLng(property.PROP_ADDR_LATI!!, property.PROP_ADDR_LONGI, property.XREF_PROPERTY, property.SPOT_COLOUR)
        dispatch(onSetSelectedPropertyId(null))
        if (emailButtonMode?.mode === 'Opened') {
            if (property.STATUS_INDEX === 1) {
                // @ts-ignore
                dispatch(onAddMapCheckedProperties({property: property}))
                dispatch(GetPropertiesEmailBody({
                    req_id: defaultReqForLinks!!,
                    gridType: 'All Properties/Upcoming',
                    lang: contactLang
                }))
                dispatch(GetPropertiesEmailPhotosForLinks({
                    p_fk_type: 'Property',
                    p_row_fk: Number(property.XREF_PROPERTY),
                    p_row_req: defaultReqForLinks!!,
                }))
            }
        }
    }

    return (
        <div
            className={s.inner}
            style={{
                height: gridContentHeight,
                maxHeight: gridContentHeight,
            }}
        >
            <div className={s.inner__form}>
                <div className={s.inner__search}>
                    <div className={s.inner__inputBox}>
                        <UniversalInput
                            type="text"
                            value={searchInput}
                            onChange={handleSearchChange}
                            labelText={''}
                            withLabel={false}
                            lineDirection="vertical"
                            className={`${styles.formInput} mapInput`}
                            labelClassName={styles.formLabel}
                            // showError={isEmptyInput && !isFreePosition}
                            errorMessage={''}
                        />
                        <div style={{
                            position: 'absolute',
                            top: '13px',
                            right: '15px'
                        }}>
                            {searchInput !== ''
                                &&
                                <p
                                    onClick={onClearSearchInput}
                                    style={{
                                        marginBottom: '0',
                                        fontSize: '12px',
                                        color: '#9CA3AF',
                                        cursor: "pointer"
                                    }}>x</p>
                            }
                        </div>
                    </div>
                    <div style={{
                        marginLeft: '16px'
                    }}>
                        <Tooltip
                            text="Please, use % in searching string, after/before the actual search value, for example Dobrovského% in order to find properties"
                            classname={'activitiesSearchInputTooltip'}>
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    </div>
                    <button onClick={onSearch} className={s.searchBtn}>Search</button>


                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '12px',
                    marginBottom: '24px'
                }}>
                    <Select
                        style={{width: 105, marginRight: '12px'}}
                        className={'mapSelect'}
                        placeholder={'Status'}
                        suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                fill={'#191559'}/>
                        </svg>}
                        onChange={handleStatusChange}
                        defaultValue={'All'}
                        options={selectMapStatuses}
                    />
                    <Select
                        style={{width: 116}}
                        defaultValue={'Zoom 2km'}
                        className={'mapSelect'}
                        suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                fill={'#191559'}/>
                        </svg>}
                        onChange={handleZoomChange}
                        options={[
                            {value: 'Zoom 1km', label: 'Zoom 1km'},
                            {value: 'Zoom 2km', label: 'Zoom 2km'},
                            {value: 'Zoom 5km', label: 'Zoom 5km'},
                            {value: 'Zoom 7km', label: 'Zoom 7km'},
                        ]}
                    />
                    <Tooltip
                        text="In active mode map will load properties on every map move, in inactive - map will not load new properties, only show already loaded or searched"
                        classname={'activitiesSwitchTooltip'}>
                        <Switch
                            size={"default"}
                            checked={isMapMovePropsActivated}
                            onChange={(e) => onSwitchChange(e)}
                        />
                    </Tooltip>
                    <div
                        className={s.notificationInfo}
                        onMouseEnter={handleItemHover}
                        onMouseLeave={handleItemLeave}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px',
                            cursor: 'pointer'
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_2681_75618)">
                                <path
                                    d="M10.0013 13.3332V9.99984M10.0013 6.6665H10.0096M18.3346 9.99984C18.3346 14.6022 14.6037 18.3332 10.0013 18.3332C5.39893 18.3332 1.66797 14.6022 1.66797 9.99984C1.66797 5.39746 5.39893 1.6665 10.0013 1.6665C14.6037 1.6665 18.3346 5.39746 18.3346 9.99984Z"
                                    stroke="#007DFA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2681_75618">
                                    <rect width="20" height="20" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    {
                        hoveredItem
                        &&
                        <div className={s.infoBlock}>
                            <h2>Status details:</h2>
                            <div className={s.infoBlock__items}>
                                <div className={s.infoBlock__item}>
                                    <span className={`${s.infoBlock__badge} ${s.infoBlock__badge_green}`}>Green</span>
                                    <span className={s.infoBlock__text}>- Indicates that the property is active, has an owner, and the last
       activity occurred within the past 14 days.</span>
                                </div>
                                <div className={s.infoBlock__item}>
                                    <span className={`${s.infoBlock__badge} ${s.infoBlock__badge_red}`}>Red</span> <span
                                    className={s.infoBlock__text}>- Indicates that the property is active but does not meet the criteria for
       the green status.</span>
                                </div>
                                <div className={s.infoBlock__item}>
                                <span
                                    className={`${s.infoBlock__badge} ${s.infoBlock__badge_gray}`}>Medium Gray</span>
                                    <span className={s.infoBlock__text}>- Indicates that the property status is null or equals -1.</span>
                                </div>
                                <div className={s.infoBlock__item}>
                                <span
                                    className={`${s.infoBlock__badge} ${s.infoBlock__badge_blue}`}>Blue</span>
                                    <span
                                        className={s.infoBlock__text}>- Indicates that the property is sold or rented.</span>
                                </div>
                                <div className={s.infoBlock__item}>
                                    <span
                                        className={`${s.infoBlock__badge} ${s.infoBlock__badge_orange}`}>Orange-brown</span>
                                    <span
                                        className={s.infoBlock__text}>- Indicates
       that the property is inactive.</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <p className={s.gridTitle}>Properties</p>
                    {
                        mapProperties!?.length && !isLoading
                            ?
                            <div style={{
                                overflowY: 'auto',
                                height: gridContentHeight - 190
                            }}>
                                {
                                    mapProperties.map((p) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    backgroundColor: selectedPropertyId === p.XREF_PROPERTY ? '#e2edf8' : '#FFF',
                                                    padding: '10px 5px',
                                                    cursor: 'pointer'
                                                }}
                                                id={String(p.XREF_PROPERTY)}
                                                onClick={() => onRowClick(p)}
                                                className={s.propertyRow}
                                            >
                                                <div>
                                                    {
                                                        emailButtonMode?.mode === 'Opened'
                                                            ?
                                                            p.STATUS_INDEX !== 1
                                                                ?
                                                                <Tooltip
                                                                    text={`You can select only properties with Active status`}
                                                                    classname={'propertiesMapTooltip'}>
                                                                    <Checkbox
                                                                        onChange={handleCheckProperties}
                                                                        name={String(p.XREF_PROPERTY)}
                                                                        checked={checkedMapProperties.some((property: MapPropertyDetails) => property.XREF_PROPERTY === p.XREF_PROPERTY)}
                                                                        disabled={true}
                                                                        style={{
                                                                            marginRight: '8px'
                                                                        }}
                                                                    />
                                                                    <span style={{
                                                                        fontWeight: 500,
                                                                        fontSize: '14px',
                                                                        lineHeight: '20px',
                                                                        color: '#111827'
                                                                    }}>{p.XREF_PROPERTY}</span>
                                                                </Tooltip>
                                                                :
                                                                <>
                                                                    <Checkbox
                                                                        onChange={handleCheckProperties}
                                                                        name={String(p.XREF_PROPERTY)}
                                                                        checked={checkedMapProperties.some((property: MapPropertyDetails) => property.XREF_PROPERTY === p.XREF_PROPERTY)}
                                                                        style={{
                                                                            marginRight: '8px'
                                                                        }}
                                                                    />
                                                                    <span style={{
                                                                        fontWeight: 500,
                                                                        fontSize: '14px',
                                                                        lineHeight: '20px',
                                                                        color: '#111827'
                                                                    }}>{p.XREF_PROPERTY}</span>
                                                                </>

                                                            :
                                                            <span style={{
                                                                fontWeight: 500,
                                                                fontSize: '14px',
                                                                lineHeight: '20px',
                                                                color: '#111827'
                                                            }}>{p.XREF_PROPERTY}</span>
                                                    }


                                                </div>
                                                <div style={{
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    color: '#111827'
                                                }}>
                                                    {`${p.PROP_ADDR_STREET_ADDRESS !== null ? `${p.PROP_ADDR_STREET_ADDRESS},` : ''} ${p.PROP_ADDR_QUARTER !== null ? `${p.PROP_ADDR_QUARTER},` : ''} ${p.PROP_ADDR_CITY !== null ? `${p.PROP_ADDR_CITY}` : ''}`}

                                                </div>
                                                {
                                                    p.STATUS !== null
                                                    &&
                                                    <div style={{
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        color: '#111827'
                                                    }}>
                                                        <p style={{
                                                            marginBottom: '0'
                                                        }}>Status: {p.STATUS}</p>
                                                    </div>
                                                }
                                                <div style={{
                                                    overflowY: p?.ownerContacts?.length !== 1 ? 'scroll' : 'auto',
                                                    maxHeight: '100px'
                                                }}>
                                                    {
                                                        p?.ownerContacts?.length
                                                            ?
                                                            <div>
                                                                {
                                                                    p?.ownerContacts?.map((o: ContactOwnerType, index: number) => {
                                                                        return (
                                                                            <div className={s.ownerItem}>
                                                                                {
                                                                                    index === 0
                                                                                        ? <>
                                                                                            {o.LAST_ACTIVITY !== null ? formatDate(o.LAST_ACTIVITY, o.ACTIVITY_COUNT) : null}

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <div>
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                <span className={s.ownerName}>{
                                                                    o.NAME !== null
                                                                        ?
                                                                        o.NAME
                                                                        :
                                                                        null
                                                                }</span>
                                                                                        <span className={s.ownerEmail}>
                                                                    {
                                                                        o.PE_EMAIL !== null
                                                                            ?
                                                                            o.PE_EMAIL
                                                                            :
                                                                            null
                                                                    }
                                                                </span>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                  <span className={s.ownerPhone}>{
                                                                      o.PE_TEL !== null
                                                                          ?
                                                                          o.PE_TEL
                                                                          :
                                                                          null
                                                                  }</span>
                                                                                        {
                                                                                            o.PE_TEL_MOBILE !== null && o.PE_TEL !== null
                                                                                                ?
                                                                                                <span
                                                                                                    className={s.ownerDivider}></span>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                        <span className={s.ownerPhone}>
                                                                    {
                                                                        o.PE_TEL_MOBILE !== null
                                                                            ?
                                                                            o.PE_TEL_MOBILE
                                                                            :
                                                                            null
                                                                    }
                                                                </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        p?.WITH_OWNERS === 1
                                                            ?
                                                            !p?.ownerContacts?.length
                                                                ?
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        dispatch(GetContactsPropertyOwners(p.XREF_PROPERTY))
                                                                    }}
                                                                    className={s.showMore}>Show owners</button>
                                                                :
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        onHideOwnerContacts(p.XREF_PROPERTY)
                                                                    }}
                                                                    className={s.showMore}>Hide owners</button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            :
                            !similarMapProperties!?.length
                                ?
                                <div style={{
                                    marginTop: '50px'
                                }}>
                                    <Empty description={'No data'}/>
                                </div>
                                :
                                null
                    }
                    {
                        mapPropertiesNotFound && similarMapProperties!?.length && !isLoading
                            ?
                            <>
                                <div>
                                    <Select
                                        style={{width: 233}}
                                        defaultValue={'Select similar address'}
                                        className={'mapSelect'}
                                        suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                                fill={'#191559'}/>
                                        </svg>}
                                        onChange={handleSimilarPropertiesSelect}
                                        options={similarMapPropertiesOptions}
                                    />
                                </div>
                                <div style={{
                                    marginTop: '50px'
                                }}>
                                    <Empty
                                        description={'No properties matched your address was found, please use field above to pick one that is similar to the address you previous searched and try again'}/>
                                </div>
                            </>
                            :
                            null

                    }
                </div>
                {
                    mapProperties!?.length
                        ?
                        <p className={s.total}>Total rows: {mapProperties!?.length}</p>
                        :
                        null
                }
            </div>

            <>
                {isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'rgba(255, 255, 255, 0.3)',
                            zIndex: 1000,
                        }}
                    >
                        <FacebookCircularProgress/>
                        <button className={s.cancelRequest}
                                onClick={onCancelRequest}>Cancel
                        </button>
                    </div>
                )}
                <MapContainer
                    center={position || [50.0874654, 14.4212535]} // Use the position state for the center
                    zoom={mapZoom}
                    style={{height: '100%', width: '100%'}}
                    scrollWheelZoom={true}
                    zoomControl={true}
                    maxZoom={18}
                    minZoom={15}
                    // bounds={polygons}
                >
                    <div style={{
                        display: 'none'
                    }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    </div>

                    <OSMMap zoomLevel={mapZoom}/>
                    {uniqueProperties.flat(1).map((p) => {
                        if (p.GPS_POINT && p.GPS_POINT.type === 'Point') {
                            return (
                                <FeatureGroup key={`${p.XREF_PROPERTY}`}>
                                    {
                                        searchPlace &&
                                        <Popup>
                                            <div>
                                                <p style={{
                                                    margin: '0'
                                                }}>{p.PROP_ADDR_STREET_ADDRESS ?? ''}, {p.PROP_ADDR_QUARTER ?? ''}, {p.PROP_ADDR_CITY ?? ''}</p>
                                                {/*{p.STATUS_INDEX === 1 && (*/}
                                                {/*    <button*/}
                                                {/*        onClick={() => handlePolygonClick(p)}*/}
                                                {/*        style={{ padding: '5px 10px', marginTop: '10px', cursor: 'pointer', backgroundColor: 'transparent', border: '2px solid #FFF'}}*/}
                                                {/*    >*/}
                                                {/*        Add to offer*/}
                                                {/*    </button>*/}
                                                {/*)}*/}
                                            </div>
                                        </Popup>
                                    }
                                    <Circle
                                        eventHandlers={{
                                            click: () => handlePolygonClick(p),
                                            mouseover: handleCircleMouseOver,
                                            mouseout: handleCircleMouseOut,
                                        }}
                                        center={p.GPS_POINT.coordinates.map((row: any) => [row[1], row[0]])[0]}
                                        color={p.SPOT_COLOUR}
                                        fillColor={p.SPOT_COLOUR}
                                        radius={mapZoom === 15 ? 20 : mapZoom === 16 ? 15 : 8}
                                    >
                                        <Popup>
                                            <div>
                                                <p style={{
                                                    margin: '0'
                                                }}>{p.PROP_ADDR_STREET_ADDRESS ?? ''}, {p.PROP_ADDR_QUARTER ?? ''}, {p.PROP_ADDR_CITY ?? ''}</p>
                                                {/*{p.STATUS_INDEX === 1 && (*/}
                                                {/*    <button*/}
                                                {/*        onClick={() => handlePolygonClick(p)}*/}
                                                {/*        style={{ padding: '5px 10px', marginTop: '10px', cursor: 'pointer', backgroundColor: 'transparent', border: '2px solid #FFF'}}*/}
                                                {/*    >*/}
                                                {/*        Add to offer*/}
                                                {/*    </button>*/}
                                                {/*)}*/}
                                            </div>
                                        </Popup>
                                    </Circle>
                                </FeatureGroup>
                            );
                        }
                    })
                    }
                    {
                        groupedDuplicates.flat(1).map((p) => {
                            if (p.GPS_POINT && p.GPS_POINT.type === 'Point') {
                                return (
                                    <FeatureGroup key={`${p.XREF_PROPERTY} ${currentSpotColor} ${clickedPropertyId}`}>
                                        {
                                            searchPlace &&
                                            <Popup>
                                                <div>
                                                    <p style={{
                                                        margin: '0'
                                                    }}>{p.PROP_ADDR_STREET_ADDRESS ?? ''}, {p.PROP_ADDR_QUARTER ?? ''}, {p.PROP_ADDR_CITY ?? ''}</p>
                                                    {/*{currentSpotColor === "#F04438" && (*/}
                                                    {/*    <button*/}
                                                    {/*        onClick={() => handlePolygonClick(p)}*/}
                                                    {/*        style={{ padding: '5px 10px', marginTop: '10px', cursor: 'pointer', backgroundColor: 'transparent', border: '2px solid #FFF'}}*/}
                                                    {/*    >*/}
                                                    {/*        Add to offer*/}
                                                    {/*    </button>*/}
                                                    {/*)}*/}
                                                </div>
                                            </Popup>
                                        }

                                        <Circle
                                            eventHandlers={{
                                                click: () => handlePolygonClick(p),
                                                mouseover: handleCircleMouseOver,
                                                mouseout: handleCircleMouseOut,
                                            }}
                                            center={p.GPS_POINT.coordinates.map((row: any) => [row[1], row[0]])[0]}
                                            color={((currentPointLatLng!?.lat === p.PROP_ADDR_LATI && currentPointLatLng!?.lng === p.PROP_ADDR_LONGI) ? currentSpotColor : p.SPOT_COLOUR)}
                                            fillColor={((currentPointLatLng!?.lat === p.PROP_ADDR_LATI && currentPointLatLng!?.lng === p.PROP_ADDR_LONGI) ? currentSpotColor : p.SPOT_COLOUR)}
                                            radius={mapZoom === 15 ? 20 : mapZoom === 16 ? 15 : 8}
                                        >
                                            <Popup>
                                                <div>
                                                    <p style={{
                                                        margin: '0'
                                                    }}>{p.PROP_ADDR_STREET_ADDRESS ?? ''}, {p.PROP_ADDR_QUARTER ?? ''}, {p.PROP_ADDR_CITY ?? ''}</p>
                                                    {/*{currentSpotColor === "#F04438" && (*/}
                                                    {/*    <button*/}
                                                    {/*        onClick={() => handlePolygonClick(p)}*/}
                                                    {/*        style={{ padding: '5px 10px', marginTop: '10px', cursor: 'pointer', backgroundColor: 'transparent', border: '2px solid #FFF'}}*/}
                                                    {/*    >*/}
                                                    {/*        Add to offer*/}
                                                    {/*    </button>*/}
                                                    {/*)}*/}
                                                </div>
                                            </Popup>
                                        </Circle>
                                    </FeatureGroup>
                                );
                            }
                        })
                    }
                </MapContainer>
            </>
            {
                openedDomainModal
                &&
                <CreationModal
                    title={'Select Domain'}
                    image={''}
                    onCancel={() => {
                    }}
                    onSave={onDomainChoose}
                    loading={false}
                    withMargin={false}
                    customButtons={true}
                    buttonOkText={'Confirm'}
                    buttonCancelText={'Cancel'}
                    isHiddenBtn={true}
                    topAndLeftFreePosition={true}
                >
                    <p className={s.modalText}>Before you start searching properties for cold offer, select a domain</p>
                    <div style={{
                        width: '100%'
                    }}>
                        <Radio.Group onChange={handleDomainChange} style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Radio value="OR">OR</Radio>
                            <Radio value="RS">RS</Radio>
                            <Radio value="CD">CD</Radio>
                            <Radio value="RR">RR</Radio>
                        </Radio.Group>
                    </div>
                </CreationModal>
            }

        </div>
    );
};


const CustomGridRow = React.memo((props: any) => {
    const dispatch = useAppDispatch()
    const contactLang = useAppSelector(selectPropertyContactLang)
    const {row} = props;
    const propContactsWindowModes = useAppSelector(selectPropContactsWindowModes)
    const emailButtonMode = propContactsWindowModes?.find((m) => m.modeName === 'Email')
    const defaultReqForLinks = useAppSelector(selectDefaultReqForLinks)


    const onRowClick = () => {
        props.onSetCurrentPointLatLng(row.PROP_ADDR_LATI, row.PROP_ADDR_LONGI, row.XREF_PROPERTY, row.SPOT_COLOUR)
        dispatch(onSetSelectedPropertyId(null))
        if (emailButtonMode?.mode === 'Opened') {
            if (row.STATUS_INDEX === 1) {
                dispatch(onAddMapCheckedProperties({property: row}))
                dispatch(GetPropertiesEmailBody({
                    req_id: defaultReqForLinks!!,
                    gridType: 'All Properties/Upcoming',
                    lang: contactLang
                }))
                dispatch(GetPropertiesEmailPhotosForLinks({
                    p_fk_type: 'Property',
                    p_row_fk: Number(row.XREF_PROPERTY),
                    p_row_req: defaultReqForLinks!!,
                }))
            }
        }
    }

    return (
        <div
            onClick={onRowClick}
            id={row.XREF_PROPERTY}
        >
            <GridRow {...props} rowHeight={row.rowHeight}/>
        </div>


    );
});

export default PropertiesMap;

