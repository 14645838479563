import React, {LegacyRef, useEffect, useRef, useState} from 'react';
import s from "../../EditProperty.module.scss";
import {Select} from "antd";
import {
    BrokeragePropertyPicturesTabData,
    onUpdateBrokeragePropertyPictureField
} from "../../../../../store/propertiesReducer";
import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {selectPropertiesPicturesTypes} from "../../../../../store/utilityReducer";

interface KindEditorPropsType {
    i: BrokeragePropertyPicturesTabData
}

const KindEditor = ({i} : KindEditorPropsType) => {
    const dispatch = useAppDispatch()
    const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown
    const [isEditing, setIsEditing] = useState(false)
    const [propertyPictureKind, setPropertyPictureKind] = useState(i.type_text)
    const picturesTypes = useAppSelector(selectPropertiesPicturesTypes)
    const propertiesPicturesTypesValues = picturesTypes.map((type: { id: number, value: string }) => ({
            value: `${type.id}`,
            label: `${type.value}`,
        }))
    const onChangePropertyPictureKind = (value: any) => {
        setPropertyPictureKind(propertiesPicturesTypesValues.find((p) => Number(value) === Number(p.value))?.label!!)
        dispatch(onUpdateBrokeragePropertyPictureField({id: i.id, fieldKey: 'type', value: Number(value)}))
        dispatch(onUpdateBrokeragePropertyPictureField({id: i.id, fieldKey: 'type_text', value: propertiesPicturesTypesValues.find((p) => Number(value) === Number(p.value))?.label!!}))

    }
    const handleDoubleClick = () => {
        setIsEditing(true);
    };


    const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        // Ignore clicks inside the dropdown
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(target) &&
            !target.closest(`.ant-select-selector`)
        ) {
            setTimeout(() => {
                setIsEditing(false);
            }, 100)

        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div ref={dropdownRef}>
            <p className={s.picture__size}>{i.width} x {i.height}</p>
            <p className={s.picture__id}>Picture number {i.id}</p>
            {/* Display or edit the Kind */}
            {!isEditing ? (
                <p className={s.picture__kind}
                   onDoubleClick={handleDoubleClick}>
                    Kind: {propertyPictureKind}
                </p>
            ) : (
                <Select
                    onChange={(e) => onChangePropertyPictureKind(e)}
                    style={{width: '100%'}}
                    placeholder={'Select kind'}
                    className={'changeKindSelect'}
                    value={propertiesPicturesTypesValues.find((p) => propertyPictureKind === p.value)?.label!!}
                    defaultValue={propertyPictureKind}
                >
                    {
                        propertiesPicturesTypesValues.map((d: { value: string, label: string }) => {
                            return (
                                <option
                                    key={d.value}
                                    value={d.value}
                                >
                                    {d.label}
                                </option>
                            )
                        })
                    }
                </Select>
            )}
        </div>
    );
};

export default KindEditor;