import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {utilityDataHelper} from "../../../helpers/localStorageHelper";
import {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {SignInDataType} from "../../../types/userTypes";
import {LoginThunk} from "../../../store/userReducer";
import classes from "../SignIn.module.scss";
import CryptoJS from 'crypto-js';
import {selectPublicKey} from "../../../store/parametersReducer";


const SignInForm = () => {
    const dispatch = useAppDispatch()
    const lastLoggedEmail = utilityDataHelper.getUserLastEmailLogged()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    const CRYPTOR_KEY = useAppSelector(selectPublicKey)
    const [ipAddress, setIpAddress] = useState('');

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIpAddress(data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        fetchIp();
    }, []);

    const handleSignIn = async (formData: SignInDataType) => {
        try {
            setIsLoading(true);
            const encryptedPassword = encrypt(formData.password, CRYPTOR_KEY);
            // Dispatch the login action with encrypted password
            dispatch(LoginThunk({
                ...formData,
                password: encryptedPassword,
                ip_address: ipAddress
            })).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                console.error('Login failed:', error);
                setIsLoading(false);
            });

        } catch (error) {
            console.error('Error during sign in:', error);
            setIsLoading(false);
        }
        finally {
            handleClearCache()
        }
    };


    useEffect(() => {
        if (lastLoggedEmail !== false) {
            form.setFieldsValue({username: lastLoggedEmail})
        }
    }, [form, lastLoggedEmail])



    // Encrypt function using CryptoJS
    function encrypt(text: string, base64Key: string): string {
        const key = CryptoJS.enc.Base64.parse(base64Key);
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    // Function to clear caches and reload clients
    const clearCachesAndReloadClients = (registration: ServiceWorkerRegistration): Promise<void> => {
        return caches.keys().then((cacheNames) => {
            return Promise.all(
                cacheNames.map((cacheName) =>
                    caches.delete(cacheName).then(() => {
                        console.log(`Cache ${cacheName} deleted.`);
                    })
                )
            );
        }).then(() => {
            console.log('All caches have been cleared.');
            // @ts-ignore
            return registration.clients.matchAll({ type: 'window' });
        }).then((clients) => {
            // @ts-ignore
            clients.forEach((client) => client.navigate(client.url));
        });
    };

    // Button click handler
    const handleClearCache = (): void => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready
                .then((registration) => {
                    if ('caches' in window) {
                        console.log('Attempting to clear caches...');
                        clearCachesAndReloadClients(registration).catch((error) => {
                            console.error('Cache clearing failed, retrying...', error);
                            setTimeout(() => {
                                clearCachesAndReloadClients(registration);
                            }, 3000);
                        });
                    }
                })
                .catch((error) => {
                    console.error('Service worker registration failed:', error);
                });
        } else {
            console.log('Service Worker and Cache API are not supported in this browser.');
        }
    };

    return (
        <Form
            name='login'
            onFinish={handleSignIn}
            autoComplete='off'
            className={classes.signInForm}
            layout='vertical'
            validateTrigger='onBlur'
            requiredMark={false}
            form={form}
        >
            <Form.Item
                name='username'
                label='E-mail'
                rules={[
                    {required: true, message: 'Please enter your email!'},
                    {type: 'email', message: 'Invalid e-mail!'},
                ]}
            >
                <Input placeholder='Enter E-mail address'/>
            </Form.Item>

            <Form.Item
                name='password'
                label='Password'
                rules={[{required: true, message: 'Please enter your password!'}]}
            >
                <Input.Password placeholder='Enter Password'/>
            </Form.Item>

            <Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    style={{width: '100%', marginTop: '20px'}}
                    loading={isLoading}
                >
                    Sign in
                </Button>
            </Form.Item>
        </Form>
    )
}

export default SignInForm