import axios from '../helpers/axiosHelper'
import {
    ParametersApiType,
    ReportApiType,
    ReportChartApiType,
    RequirementsApiType,
    TemplatesApiType,
    UserApiType
} from '../types/apiTypes'
import {GetActivityCodesReqData, MarketingReportType, UpdateActivityCodesReqData} from "../types/parametersTypes";
import {Moment} from "moment";
import {CreateAndUpdateUserAccess} from "../types/userTypes";
import {CreateAndUpdateReqFields} from "../types/requirementsTypes";
import {CreateNewPropertyPicture, UpdateBrokerageProperty} from "../store/propertiesReducer";


export const userAPI: UserApiType = {
    signIn(loginData) {
        return axios.post('/login', loginData)
    },
    checkUserRole(data: CheckPersonBelongsToGroup) {
        return axios.post(`/users/check-belongs-to-group`, data)
    },
    getAgentAddress(user_ref: string) {
        return axios.get(`/agents/address?user_ref=${user_ref}`)
    },
    integrateWithGoogle() {
        return axios.get(`/google/authorization/url`)
    },
    finishGoogleIntegration(code: string) {
        return axios.get(`/google/authorization/code?code=${code}`)
    },
    googleLogout() {
        return axios.delete(`/google/logout`)
    },
    checkIsGoogleIntegrated(user_ref: number){
        return axios.get(`/users/check-is-google-integrated?user_ref=${user_ref}`)
    },
    checkIsUserHasAddedPropsAndReq(){
        return axios.get(`/users/is-user-has-added-prop-and-req`)
    },
    appLogout(){
        return axios.get('/login/logout')
    },
    getUserAccesses(){
        return axios.get('/user-accesses')
    },
    getUserRoles(){
        return axios.get('/users/roles')
    },
    getMenuItemsList(){
        return axios.get('/menu-items')
    },
    createNewUserAccess(reqData: CreateAndUpdateUserAccess){
        return axios.post('/user-accesses', reqData)
    },
    deleteUserAccess(user_access_id: number){
        return axios.delete(`/user-accesses/${user_access_id}`)
    },
    updateUserAccess(reqData: CreateAndUpdateUserAccess, user_access_id: number){
        return axios.put(`/user-accesses/${user_access_id}`, reqData)
    }
}

export const reportAPI: ReportApiType = {
    getDomainsByPeriod() {
        return axios.post(`/companies/period`)
    },
    getActivityReport(data) {
        return axios.post('/stats/agents', data, {responseType: 'blob'})
    },
    getAgentLastContact(data) {
        return axios.post('/activitites/agent-contact', data, {responseType: 'blob'})
    },
    getPropertyLastContact(data) {
        return axios.post('/activitites/prop-contact', data, {responseType: 'blob'})
    },
    getValuatedPropertiesLastContact(data) {
        return axios.post('/activitites/val-prop-contact', data, {responseType: 'blob'})
    },
    getRequirementLastContact(data) {
        return axios.post('/activitites/req-contact', data, {responseType: 'blob'})
    },
    getReportByCategories(category_id: number) {
        return axios.get(`/reports/report-categories/${category_id}`)
    },
    getFraudReportsDiagram(reqData: {
        end_period: Moment | null
        start_period: Moment | null
        value_count: number | null
    }) {
        return axios.post(`/fraud-reports/email-count/statistic`, reqData)
    },
    getCountPeriodOrganization(reqData: FraudReportsReq) {
        return axios.post(`fraud-reports/period-org-count`, reqData, {responseType: 'blob'})
    },
    getMaximumCountOfPropertiesInOneEmail(reqData: FraudReportsReq) {
        return axios.post(`/fraud-reports/email-count`, reqData, {responseType: 'blob'})
    },
    getMaximumCountOfPropertiesInThePeriodContacts(reqData: FraudReportsReq) {
        return axios.post(`/fraud-reports/period-count`, reqData, {responseType: 'blob'})
    }
}


export const reportChartAPI: ReportChartApiType = {
    getActivityChartInfo(dates) {
        return axios.post('/stats/stat-diagram', dates)
    },
    getAgentLastContactChartInfo(dates) {
        return axios.post('/activitites/stats/agent-contact', dates)
    },
    getPropertyLastContactChartInfo(dates) {
        return axios.post('/activitites/stats/prop-contact', dates)
    },
    getValuatedPropertiesLastContactChartInfo(dates) {
        return axios.post('/activitites/stats/val-prop-contact', dates)
    },
    getRequirementLastContactChartInfo(dates) {
        return axios.post('/activitites/stats/req-contact', dates)
    },


}

export const parametersAPI: ParametersApiType = {
    getDisclaimers() {
        return axios.get('/disclaimer-infos')
    },
    updateDisclaimers(disclaimer_info_list) {
        return axios.put('/disclaimer-infos', {disclaimer_info_list})
    },
    getActivityCodeAttributes(req: GetActivityCodesReqData){
        return axios.post(`/grids/activity-list`, req)
    },
    updateActivityCodeAttributes(req: {activity_lists: UpdateActivityCodesReqData[]}){
        return axios.put(`/activity-lists/list`, req)
    },
    createActivityCodeAttributes(req: UpdateActivityCodesReqData){
        return axios.post(`/activity-lists`, req)
    },
    deleteActivityCode(req: UpdateActivityCodesReqData){
        return axios.delete(`/activity-lists`, {data: req})
    }
}

export const templatesApi: TemplatesApiType = {
    getTemplates() {
        return axios.get('/templates')
    },
    getTemplate(code: string) {
        return axios.get(`/templates/${code}`)
    },
    updateTemplates(marketingTemplates: MarketingReportType, code: string) {
        return axios.put(`/templates/${code}`, marketingTemplates)
    },
    soldPropertiesTemplate(reqData: SoldPropertyReqData){
        return axios.post(`/templates/sold-properties`, reqData)
    }
}

export const requirementsApi: RequirementsApiType = {
    getRequirements(user_ref: string) {
        return axios.get(`/requirements?user_ref=${user_ref}`)
    },
    getRequirementsProperties() {
        return axios.post(`/requirements/properties`)
    },
    getEmailBody(req_id: number | null, lang: 'EN' | 'CZ') {
        if (req_id !== null) {
            return axios.get(`/requirements/email-body?req-id=${req_id}&lang=${lang}`)
        } else {
            return axios.get(`/requirements/email-body?lang=${lang}`)
        }
    },
    getEmailLinkText(prop_id: number, req_id: number, lang) {
        return axios.get(`/requirements/email-link-text?prop-id=${prop_id}&req-id=${req_id}&lang=${lang}`)
    },
    getDomainForEmail(req_id: number) {
        return axios.get(`/requirements/domain?req-id=${req_id}`)
    },
    getEmailGreeting(req_id: number) {
        return axios.get(`/requirements/greeting?req-id=${req_id}`)
    },
    createPropertyOffer(requestBody: CreatePropertyOffer | CreatePropertyOffer[]) {
        return axios.post(`/activitites/offer`, {activity_offers: requestBody})
    },
    getEmailTemplates(template_type: EmailTemplateTypes) {
        return axios.get(`/requirements/signatures?template-type=${template_type}`)
    },
    updateEmailBody(requestBody: EmailUpdate) {
        return axios.put(`/requirements/email-body`, requestBody)
    },
    updateEmailSubject(requestBody: EmailUpdate) {
        return axios.put(`/requirements/email-subject`, requestBody)
    },
    checkIsGridReqMatch(gridReqId: number) {
        return axios.post(`/grids/req-match`, {row_req: gridReqId})
    },
    checkIsGridBrokerageReqDataMatch(prop_id: number) {
        return axios.post(`/grids/prop-match`, {prop_ref: prop_id})
    },
    checkSoldPropertyUrl(url: string){
        return axios.post(`/requirements/properties/sold`, {url})
    },
    brokerageIsReqExisted(prop_ref: number, user_ref: number){
        return axios.get(`/requirements/is-existed?prop_ref=${prop_ref}&user_ref=${user_ref}`)
    },
    getCriteriasForReqEditing(req_ref: number){
        return axios.get(`/requirements/${req_ref}/criterias`)
    },
    getRequirementsContactFunctions(){
        return axios.get(`/requirements/contact-functions`)
    },
    getDefaultRequirementForMapOffer(domain: 'CD' | 'OR' | 'RR' | 'RS' | null | 'null' | string){
        return axios.get(`/requirements/default?domain=${domain}`)
    },
    createReqForColdOffer(req: any){
        return axios.post('/requirements/cold-offer', req)
    }
}

export const gridApi = {
    getGridColumnData(request: GridReqType) {
        return axios.post(`/grids/column-data`, request)
    },
    getGridColumnDataTest(request: GridReqType, options?: { signal?: AbortSignal }) {
        return axios.post(`/grids/column-data`, request, {
            signal: options?.signal
        })
    },
    updateGridColumnData(request: UpdateGridReqType) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.put(`/grids/list-properties`, request, {headers})
    },
    getEmailSubject(req_id: number, user_id: string) {
        return axios.get(`/requirements/email-subject?req-id=${req_id}&user-id=${user_id}`)
    },
    resetPropertiesGrid(p_agent_ref: number, p_row_req: number | null, grid_type: null | string) {
        return axios.delete(`/grids/list-properties`, {
            data: {
                p_agent_ref,
                p_row_req,
                grid_type
            }
        })
    },
    getLinksPhotos(p_fk_type: string, p_row_fk: number | undefined, p_row_req: number) {
        return axios.get(`/photos?p_fk_type=${p_fk_type}&p_row_fk=${p_row_fk}&p_row_req=${p_row_req}`)
    },
    getPropertiesSurface(lang: string, prop_id: string, req_id: string) {
        return axios.get(`/requirements/surf-details?lang=${lang}&prop-id=${prop_id}&req-id=${req_id}`)
    },
    getPropertyActivities(reqData: ActivitiesReqDataType) {
        return axios.post<PropertyActivityDataType>(`/activitites/all`, reqData)
    },
    getAuditGridData(reqData: any) {
        return axios.post(`/grids/user-activities`, reqData)
    },
    getAllPropertiesWithREQGridData(reqData: any,  options?: { signal?: AbortSignal }) {
        return axios.post(`/grids/prop-with-req`, reqData, {
            signal: options?.signal
        })
    },
    getAllPropertiesWithoutREQGridData(reqData: any, options?: { signal?: AbortSignal }) {
        return axios.post(`/grids/prop-contact`, reqData, {
            signal: options?.signal
        })
    },
    getAllPropertiesAddressGridData(requestData: {
        date_from: Moment | string,
        date_to: Moment | string
    }) {
        return axios.post(`/grids/address-properties`, requestData)
    },

    getBrokerageReqs(prop_ref: string) {
        return axios.get(`/requirements?prop_ref=${prop_ref}`)
    },
    updateAddressProperty(addressProperty: AddressPropertyUpdated) {
        return axios.put(`/address-properties`, addressProperty)
    },
    getGridActivitiesData(reqData: GridActivitiesRequest) {
        return axios.post(`/grids/activities`, reqData)
    },
    getCurrentAddressData(reqData: any) {
        return axios.post(`/address-properties/parse-osm-data`, {
            "json_data": reqData
        })
    },
    getGridSurfaceData(reqData: GridReqType) {
        return axios.post(`/grids/surf-properties`, reqData)
    },
    getMapTabProperties(search_data: any, options?: { signal?: AbortSignal }){
        return axios.post(`/address-properties/map`, search_data, {
            signal: options?.signal
        })
    },
    getMapTabStatuses(){
        return axios.get(`/requirements/prop-statuses`)
    },
    getRequirementsGridData(reqData: {
        "p_agent_ref": number,
        "p_end_period": Date,
        "p_sort_order": string | null,
        "p_start_period": Date
    }){
        return axios.post(`/grids/requirements`, reqData)
    },
    getAllPropertiesGridCurrentPropertyReq(prop_ref: string) {
        return axios.get(`/requirements?prop_ref=${prop_ref}`)
    },
    getBrokeragePropertiesReqsData(reqData: any){
        return axios.post(`/grids/brokerage/reqs`, reqData)
    },
    getAgentsReportsGridData(reqData: {
        "agent_ref": number,
        "domain": string,
        "position": string | null,
        "report_name": string
    }){
        return axios.post(`/grids/agent-report-rank`, reqData)
    },
    createAgentReportRank(reqData: any){
        return axios.post(`/agent-reports/rank`, {agent_report_ranks: reqData})
    },
    getReqByContactId(contact_ref: number){
        return axios.get(`/requirements/contacts/${contact_ref}`)
    },
    getReqByCompanyId(company_ref: number){
        return axios.get(`/requirements/companies/${company_ref}`)
    },
    createRequirement(req: any){
        return axios.post(`/requirements`, req)
    },
    updateRequirement(req: CreateAndUpdateReqFields, req_ref: number){
        return axios.put(`/requirements/${req_ref}`, req)
    },
    updateRequirementForColdOffer(req: CreateAndUpdateReqFields, req_ref: number){
        return axios.put(`/requirements/cold-offer/${req_ref}`, req)
    },
    getRequirementsByContactId(reqData: {p_agent_ref: number, p_contact_ref: number, p_sort_order: string | null}){
        return axios.post(`/grids/contacts/requirements`, reqData)
    },
    getRequirementsColdOffers(reqData: {p_agent_ref: number}){
        return axios.post(`/grids/requirements/cold`, reqData)
    },
    getRequirementsColdOffersLinkedProperties(req_ref: number){
        return axios.get(`requirements/cold-offer/properties?req-ref=${req_ref}`)
    },
    getBrokeragePropertiesByPropId(ref: number){
        return axios.get(`/properties/${ref}`)
    },
    updateBrokerageProperty(ref: number, req: UpdateBrokerageProperty){
        return axios.put(`/properties/${ref}`, req)
    }
}

export const contactsApi = {
    getContactsDefault(req_id?: number) {
        return axios.get(`/contacts?req_id=${req_id}&is_mail=1`)
    },
    getContactsDefaultUnchecked(req_id?: number) {
        return axios.get(`/contacts?req_id=${req_id}&is_mail=0`)
    },
    getContacts() {
        return axios.get(`/contacts/all`)
    },
    checkIfContactIsInBlackList(email: string) {
        return axios.post(`/contacts/check`, {email})
    },
    getContactByOrganization(company_ref: number) {
        return axios.get(`/contacts/companies/${company_ref}`)
    },
    getContactByOrganizationAndActivityRef(company_ref: number, activity_ref: number) {
        return axios.get(`/contacts/companies/${company_ref}?activity-ref=${activity_ref}`)
    },
    getContactLang(req_id: number){
        return axios.get(`/contacts/language?req_id=${req_id}`)
    },
    createContactFromOffer(reqData:any){
        return axios.post(`/contacts`, reqData)
    },
    getPropertyOwnerContacts(agent_ref: string, prop_ref: string){
        return axios.get(`/contacts/property-owners?agent_ref=${agent_ref}&prop_ref=${prop_ref}`)
    },
    getContactsGenderTitles() {
        return axios.get(`/contacts/gender-titles`)
    },
    getContactsJobPositions() {
        return axios.get(`/contacts/job-positions`)
    },
    getContactsProfessionTitles() {
        return axios.get(`/contacts/profession-titles`)
    },
    updateContactRemark(co_ref: number | null, remark: string) {
        return axios.put(`/contacts/${co_ref}/remarks`, {
            "remarks": remark
        })
    },
    getContactsPropertyOwners(prop_ref: number) {
        return axios.get(`/contacts/property-owners/map?prop_ref=${prop_ref}`)
    },

}

export const countryApi = {
    getAllCountries() {
        return axios.get(`/countries`)
    }
}

export const activitiesApi = {
    getActivitiesTypeKind(reqData: ActivityTypeKindReqData) {
        return axios.post(`/activitites/type-kinds`, reqData)
    },
    getFiltersActivitiesTypeKind() {
        return axios.post(`/activitites/type-kinds`, {})
    },
    getFiltersActivitiesTypes() {
        return axios.get(`/activitites/types`)
    },
    getActivitiesTypes(domain: string, position?: string | null) {
        if (position !== undefined && position !== null) {
            return axios.get(`/activitites/types?domain=${domain}&position=${position}`)
        } else if(domain !== undefined && domain !== 'null' && domain !== null) {
            return axios.get(`/activitites/types?domain=${domain}`)
        }
    },
    getActivitiesRequirements(reqData: {
        p_agent_ref: number
        p_company_ref?: number | null
        p_sort_order: string | null
        p_domain?: string | null
        // p_agent_ref: number
        // p_contact_ref: number | null
        // p_sort_order: string | null
        // p_status_idx: number
    }) {
        return axios.post(`/grids/activities/requirements`, reqData)
    },
    getActivitiesPropertiesByReq(reqData: {
        p_agent_ref: number
        p_req_ref: number
        p_sort_order: string | null
    } ) {
        return axios.post(`/grids/activities/requirements/properties`, reqData)
    },
    getActivitiesPropertySurface(req_id: number, prop_id: number) {
        return axios.get(`/requirements/surf-details?req-id=${req_id}&prop-id=${prop_id}`)
    },
    createNewActivity(requestData: CreateAndUpdateActivityRequest) {
        return axios.post(`/activitites`, requestData)
    },
    updateExistingActivity(requestData: CreateAndUpdateActivityRequest, reqRef: number) {
        return axios.put(`/activitites/${reqRef}`, requestData)
    },
    deleteActivity(activityRef: number) {
        return axios.delete(`/activitites/${activityRef}`)
    },
    getActivityRefLinks(activityRef: number) {
        return axios.get(`/activitites/${activityRef}/links`)
    },
    activitiesCheckPassword(reqData: CheckPasswordData) {
        return axios.post(`/grids/check-password`, reqData)
    },
    activitiesCheckIsPasswordNeeded(reqData: CheckPasswordData) {
        return axios.post(`/grids/is-need-password`, reqData)
    },
    searchCompaniesByContact(search_value: string | null, address?: string, pe_ref?: string){
        return axios.post(`/contacts/search`, {search: search_value, address: address, pe_ref: pe_ref})
    },
    getActivitiesProperties(reqData: {
        p_agent_ref: number
        p_company_ref?: number | null,
        p_contact_ref?: number | null
        p_sort_order: string | null,
        p_status_idx: number | null,
        p_function_idx?: number | null,
        p_street_address?: string | null,
        p_domain: string | null,
        // p_agent_ref: number
        // p_contact_ref: number | null
        // p_sort_order: string | null
        // p_status_idx: number
    }, options?: { signal?: AbortSignal }) {
        return axios.post(`/grids/activities/properties`, reqData, {
            signal: options?.signal
        })
    },
    getActivitiesSurfaces(reqData: {
        p_agent_ref: number
        p_company_ref?: number | null,
        p_contact_ref?: number | null
        p_sort_order: string | null,
        p_status_idx: number,
        p_function_idx?: number | null,
        p_street_address?: string | null,
        p_domain: string | null,
        // p_agent_ref: number
        // p_contact_ref: number | null
        // p_sort_order: string | null
        // p_status_idx: number
    }, options?: { signal?: AbortSignal }) {
        return axios.post(`/grids/activities/surfaces`, reqData, {
            signal: options?.signal
        })
    },
}


export const companiesApi = {
    getAllCompanies(name: string) {
        return axios.post(`/companies/search`, {search: name})
    },
    getCompanyByRef(company_ref: number) {
        return axios.get(`/companies/${company_ref}`)
    },
    getCompaniesByContactRef(person_ref: number){
        return axios.get(`/companies/persons/${person_ref}`)
    },
    getCompaniesByReqRef(req_ref: number){
        return axios.get(`/companies/requirements/${req_ref}`)
    },
    getCompanySources(){
        return axios.get(`/catalogs/company-sources`)
    },
    getLanguages(){
        return axios.get(`/catalogs/languages`)
    },
    getPropertyTypes(){
        return axios.get(`/catalogs/estate-types`)
    },
    getPrahaCityParts(){
        return axios.get(`/catalogs/prague/city-parts`)
    },
    getAccomidationTypes(){
        return axios.get(`/catalogs/accommodation-types`)
    },
    getReconstructionTypes(){
        return axios.get(`/catalogs/reconstruction-types`)
    },
    getCurrencies(){
        return axios.get(`/catalogs/currencies`)
    },
    getSurfaceUsages(){
        return axios.get(`/catalogs/surface-usages`)
    }
}

export const eventsApi = {
    getEventsByStatus(requestData: {
        event_status: string[] | []
        user_ref: number,
        date_from: Date,
        date_to: Date
    }) {
        return axios.post(`/events/filter`, requestData)
    },
    getEventByActivityNumber(activity_ref: number){
        return axios.get(`/activitites/${activity_ref}`)
    },
    updateEventStatus(event_id: number, requestData: {
        action_date: Moment | string
        event_status: {
            description: string | null
            eventStatus: string
        },
        is_read: number
    }){
        return axios.put(`/events/${event_id}`, requestData)
    },
    getEventById(event_id: number){
        return axios.get(`/events/${event_id}`)
    },
    sendLetterToOwner(reqData: any){
        return axios.post(`/activitites/send-letter`, reqData)
    },
    getContactsByEventId(event_id: number){
        return axios.get(`/events/${event_id}/contacts`)
    },
    updateEventContact( event_id: number,
                        contacts: string){
        return axios.put(`/events/${event_id}/update-contacts`, {contacts: contacts})
    },
    updateEventDomain( event_id: number,
                        domain: string){
        return axios.put(`/events/${event_id}/update-domain`, {domain: domain})
    }
}

export const faqsApi = {
    getFaqs(category: 'Admin' | 'User'){
        return axios.get(`/faqs?category=${category}`)
    },
    searchFaqs(requestData: {activity_ref: number, search: string}){
        return axios.post(`/faqs/search`, requestData)
    },
}

export const mapApi = {
    getSimilarMapProperties(req: SimilarAddressProperties){
        return axios.post(`/address-properties/similar`, req)
    },
}

export const realmanApi = {
    uploadToRealman(req: UploadToRealman, config = {}) {
        return axios.post(`https://realman.primoportal.com/realman/adverts`, req, config);
    }
}

export const catalogsApi = {
    getPropertiesTypes() {
        return axios.get(`/catalogs/property-classes`);
    },
    getPropertyPictureTypes(){
        return axios.get(`/catalogs/picture-types`)
    }
}

export const propertiesApi = {
    findPropertyImagesByPropRef(prop_ref: number) {
        return axios.get(`/property-images/properties/${prop_ref}`);
    },
    updatePropertyImagesByPropRef(prop_ref: number, reqData: UpdatePropertyPictures) {
        return axios.put(`/property-images/${prop_ref}`, reqData);
    },
    deletePropertyImageByPropRef(prop_ref: number) {
        return axios.delete(`/property-images/${prop_ref}`);
    },
    createPropertyImageByPropRef(prop_ref: number, reqData: CreateNewPropertyPicture) {
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        return axios.post(`/property-images/properties/${prop_ref}`, reqData, {headers});
    },
    updatePropertyImageIndex(id: number, reqData: {
        "index_old": number,
        "index_new": number
    }) {
        return axios.post(`/property-images/${id}/update-index`, reqData);
    },
}


export const protocolsApi = {
    getProtocolsForGrid(reqData: {
        p_sort_order: string | null
        p_start_period: string | null
        p_end_period: string | null
        p_search_value: string | null
        p_agent_ref: number | null
        p_status: number | null
        p_page: number | null
        p_size: number | null
    }) {
        return axios.post(`/grids/protocols`, reqData);
    },
}

export type GridReqType = {
    p_agent_ref: number
    p_row_req: number
    p_sort_order: any
    p_where_cond?: string
    page?: number
    size?: number
    p_prop_req?: number | null
}

export type UpdateGridReqType = {
    p_agent_ref: number
    p_grid_columns: any[],
    p_row_req?: number | null
    p_sort_order: string | null
}

export type CreatePropertyOffer = {
    p_agent_ref: number
    p_domain_cd: string
    p_lang_cd: string
    p_mail_bcc_list: string
    p_mail_body: string
    p_mail_cc_list: string
    p_mail_subject: string
    p_mail_to_list: string
    p_prop_list: string
    p_req_ref: number | null
    p_surf_list: string
}

export type CheckPersonBelongsToGroup = {
    account_names: string[],
    user_ref: number
}

export type EmailUpdate = {
    domain: string
    email_text: string
    language: string
    template_type: EmailTemplateTypes
}

export type ActivitiesReqDataType = {
    req_id: number | null
    row_pro: number
    surf_id?: number | null
    user_id: number
}
export type PropertyActivityType = {
    CONTACTS: string;
    ACTIVITY_TYPE: string;
    AGENT_CREA_ACT: string;
    AGENT_MODI_ACT: string;
    DATE_CREA_ACT: string;
    DATE_MODI_ACT: string;
    DESC_SCT: string;
    REF_ACT: number;
    REMARK_ACT: string;
};

export type PropertyActivityDataType = {
    resultSetList: any
}

export type AuditGridItems = {
    user_activities: AuditGridItem[]
}

export type AuditGridItem = {
    action: string
    activity_time: string
    id: number
    ip: string
    link: string
    prop_ref: number
    req_ref: number
    surf_ref: number
    user_ref: number
}

export interface FraudReportsReq {
    end_period: Moment
    start_period: Moment
    value_count: number
}

export type AllPropertiesReqType = {
    resultSetList: AllPropertiesReqItem[]
}

export type AllPropertiesReqItem = {
    CO_EMAIL: string | null;
    CO_NAME: string;
    CREATED_BY: string;
    DATE_CREATED: string;
    DATE_MODIFIED: string;
    EMAIL_LIST: string;
    INFORMER: string;
    MODIFIED_BY: string;
    REMARKS: string;
    REQ_ID: number;
};

export type AddressPropertyUpdated = {
    addr_city: string
    addr_nf_no_from: number | null
    addr_nf_no_to: number | null
    addr_no_connect: string | null | number
    addr_no_from_rest: string | null | number
    addr_no_to_rest: string | null | number
    addr_quarter: string | null
    addr_state_admin_level_1: string | null
    addr_street: string | null
    addr_zip: string | null
    cntry_desc_local: string | null
    is_default_addr: boolean
    prop_addr_ref: number
    prop_ref: number
    latitude: number
    longitude: number,
}

export type GridActivitiesRequest = {
    p_agent_ref: number
    p_end_period: Date | null | string
    p_sort_order: string | null
    p_start_period: Date | null | string
}

export interface CreateAndUpdateActivityRequest {
    date_begin: string | Moment,
    date_end: string | Moment,
    description: string | null,
    link_entity_list: any,
    link_ref_list: number[] | null[] | (number | null)[],
    link_type_list: (number | null)[],
    priority: number,
    remark: string | null,
    type: any
    type_kind: any
}

export interface CheckPasswordData {
    list_code: string
    operation: string
    password: string | null
}

export interface ActivityTypeKindReqData {
    activity_type_id:number
    domain: string
    position: string
}

export interface SoldPropertyReqData {
    "domain": string,
    "lang": string,
    "row_prop": number,
    "row_req": number,
    "row_surf": number | null
}

export interface SimilarAddressProperties {
    date_from?: string,
    date_to?: string,
    domain: string
    search_data: string,
    status_index: number | null,
    zoom_request?: {
        lanbottom: number,
        lantop: number,
        latbottom: number,
        lattop: number
    }
}

export interface UploadToRealman {
    "session_mode": string,
    "prop_ids": string[],
    "domains": string[] }


export interface UpdatePropertyPictures {
    "filename": string
    "format": string
    "title": string
    "description": string
    "type": number
    "for_expose": number
    "for_portal": number
    "for_presentation": number
    "is_ref": number
    "is_pme": number
    "is_locked": number
    "index": number
    "width": number
    "height": number
}

export type EmailTemplateTypes = 'OFFER' | 'OWNER' | 'EVENT'