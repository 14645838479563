import {Navigate, Outlet, Route, Routes, useNavigate} from 'react-router'
import Header from '../components/common/Header/Header'
import {
    selectAppStatus,
    selectErrorMessage, selectIsAbortedPromise,
    selectSuccessMessage, setAppStatus, setErrorMessage,
} from '../store/appStatusReducer'
import {selectIsExternalLink, selectIsLoggedIn, setIsExternalLink} from '../store/userReducer'
import './App.less'
import {useAppDispatch, useAppSelector} from './hooks'
import routes from './routes'
import {LicenseInfo} from '@mui/x-license-pro';
import {Alert} from '@mui/material'
import {Snackbar} from "@material-ui/core";
import {useEffect, useState} from "react";
import {requirementsApi} from "./api";
import {utilityDataHelper} from "../helpers/localStorageHelper";
import ScrollToTop from "../components/common/ScrollToTop/ScrollToTop";


function App() {
    LicenseInfo.setLicenseKey('db3fc75e0cadffe514d0f715907ff883Tz0xMDU0NjcsRT0xNzY4MDg5NTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');
    const appStatus = useAppSelector(selectAppStatus)
    const error = useAppSelector(selectErrorMessage)
    const successMessage = useAppSelector(selectSuccessMessage)
    const isAbortedPromise = useAppSelector(selectIsAbortedPromise)
    const location = window.location.href
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const isExternalLink = useAppSelector(selectIsExternalLink)
    const [isNotValidLink, setIsNotValidLink] = useState(false)

    useEffect(() => {
        if (location.includes('/#/p') || location.includes('/#/s')) {
            dispatch(setIsExternalLink(true))
            requirementsApi.checkSoldPropertyUrl(window.location.href)
                .then((res: any) => {
                    if (res.data.code === 302) {
                        window.location.replace(res.data.headers.Location)
                    } else if (res.data.code === 410) {
                        utilityDataHelper.setSoldPropertiesReqData(res.data.reqInfo)
                        const path = `/soldPropertiesPage/sold${location.split('/#')[1]}`
                        window.location.replace(location.split('/#')[0] + path.replace(/\/\//g, '/'));
                    } else if (res.data.code === 400) {
                        dispatch(setErrorMessage(res.data.message))
                        dispatch(setAppStatus(3))
                        setIsNotValidLink(true)
                    }
                })
        } else {
            dispatch(setIsExternalLink(false))
        }
    }, [location, navigate, dispatch])


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            dispatch(setAppStatus(0))
            return dispatch(setAppStatus(0));
        }
        dispatch(setAppStatus(0))
        return dispatch(setAppStatus(0));

    };


    return (
        !isExternalLink && isExternalLink !== null
            ?
            <div className='app'>
                <Snackbar open={appStatus === 3 && !isAbortedPromise} autoHideDuration={7}
                          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert severity="error" sx={{
                        width: '100%', vertical: 'top',
                        horizontal: 'center',
                    }}
                           onClose={handleClose}
                    >
                        {error}
                    </Alert>
                </Snackbar>
                <Snackbar open={appStatus === 2} autoHideDuration={7}
                          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert severity="success" sx={{width: '100%',}} onClose={handleClose}>
                        {successMessage}
                    </Alert>
                </Snackbar>
                <Routes>
                    {routes.map((route) => (
                        route.auth ? (
                            <Route key={route.path} path={route.path} element={<PrivateRoute {...route.props}/>}>
                                <Route path={route.path} element={<route.component {...route.props}/>}/>
                            </Route>
                        ) : (
                            <Route key={route.path} element={<route.component {...route.props}/>}
                                   path={route.path}/>
                        )
                    ))}
                </Routes>
            </div>
            :
            isNotValidLink
                ?
                <div>
                    <Snackbar open={appStatus === 3 && !isAbortedPromise} autoHideDuration={7}
                              anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                        <Alert severity="error" sx={{
                            width: '100%', vertical: 'top',
                            horizontal: 'center',
                        }}
                               onClose={handleClose}
                        >
                            {error}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={appStatus === 2} autoHideDuration={7}
                              anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                        <Alert severity="success" sx={{width: '100%',}} onClose={handleClose}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </div>
                :
                <div style={{
                    margin: '0',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: 1.4,
                    color: "#212529",
                    textAlign: "left",
                    padding: '0 15px'

                }}>
                    Loading...
                </div>
    )
}

const PrivateRoute = () => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn)

    return isLoggedIn ? (
        <div>
            <ScrollToTop/>
            <Header/>
            {/*<HeaderLinks/>*/}
            <Outlet/>
        </div>
    ) : (
        <Navigate to='/sign-in'/>
    )
}


export default App
